import {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import '../style/NewServiceProviderSelectStyle.css'
import {IconButton} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";
import CreateSite from "./CreateSite";

export default function SelectClientPrefSite({closeModal}){

    let api = new IbatApi();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selected, setSelected] = useState()
    const [site, setSite] = useState()
    const [filter, setFilter] = useState({cp:"", address:"", ville: ""})


    useEffect(()=>{

        loadOptions()
    }, [filter])

    let loadOptions=()=>{
        return api.getSiteFilterd(filter).then(resp => {
            setSite(resp.content)
        })
    }

    function handleInputChange(d){
        // setName(d)
        setFilter( {...filter, [d.target.id]: d.target.value})
        // loadOptions()
    }
    function getSiteRow(c){
        return (
            <tr className={selected === c.id ? "selected": "result-row"} onClick={()=>setSelected(c.id)}>
                <td>{c.id}</td>
                <td>{c.name}</td>
                <td>{c.address}</td>
                <td>{c.cp}</td>
                <td>{c.ville}</td>
            </tr>
        )
    }
    function setPrefSite() {
        api.setPrefSite(selected)
        setFilter({...filter, cp: ""})
        enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
        setSelected()
    }

    return (
        <div className={"new-service-provider-select"}>
            <div className={"filters"}>
                <input type={"number"} onChange={handleInputChange} placeholder={"Code postal"} id={"cp"}/>
                <input type={"text"} disabled={!filter.cp} onChange={handleInputChange} placeholder={"Ville"} id={"ville"}/>
                <input type={"text"} disabled={!filter.ville} onChange={handleInputChange} placeholder={"Adresse"} id={"address"}/>
            </div>

            {site &&
                <table className={"result"}>
                    <tr>
                        <td>id</td>
                        <td>Nom</td>
                        <td>Adresse</td>
                        <td>Code Postal</td>
                        <td>Ville</td>
                    </tr>
                    {site?.map(c=>getSiteRow(c))}
                </table>
            }


            <div className={"inline"}>
                <IconButton className={"validation-button"} onClick={closeModal}>{"Terminer"}</IconButton>
                {selected &&
                    <IconButton className={"validation-button"} onClick={setPrefSite}>{"Ajouter ce site à ma liste"}</IconButton>
                }
            </div>
        </div>
    )
}