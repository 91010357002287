import Tooltip from "@material-ui/core/Tooltip";
import React, {Fragment, useEffect,useState} from "react";
import {useDrag} from "react-dnd";
import constants from "../components/util/constants";
import "./StepFlatStyle.css"
import EditSupply from "../components/forms/EditSupply";
import Modal from "react-responsive-modal";
import Popper from "@material-ui/core/Popper";
import InteractionClient from "../components/bean/InteractionClient";
import {IconButton} from "@material-ui/core";
import { text } from "../components/util/constants";
import TimeSelector from "../components/util/TimeSelector";
import {isModifiable} from "../helpers/interventionManager";

export default function StepFlat({event,pin,onHover,onRemove,onTimeChange,containerSize,onDrag}){
    const [dialog,setDialog]=useState(false)
    const [modal,setModal]=useState(null)
    function handleTimeChange(newTime){
        onTimeChange(event.id,newTime/1000)
        setModal(null)

    }

    const [{isDragging}, drag] = useDrag(() => ({
        type:"event",
        item:event,
        end:(item,monitor)=>isModifiable(pin) && onDrag(pin.id),
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging() && isModifiable(pin)
        })
    }))
    return(!isDragging &&
        <Fragment>
            <div className={ `step-flat ${ !isModifiable(pin) && "fix"}`} ref={isModifiable(pin)?drag:null}
                 onClick={(e=>setDialog(dialog?null:e.currentTarget))}
                 style={{height:containerSize*((event.endEvent-event.start))/(15*constants.M1)}}
                 onMouseEnter={()=>onHover(pin.id)}
                 onMouseLeave={()=>onHover(null)}>

                <Tooltip title={pin.devis.site?.name}>
                    <div className={"site"} style={{fontSize:(event.endEvent-event.start)*constants.S1>15*constants.M1?15:containerSize}} >
                        <sapn>{pin.devis.site?.name}</sapn>
                    </div>
                </Tooltip>
            </div>
            <Popper
                className={"popper"}
                placement="left"
                disablePortal={true}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                    arrow: {
                        enabled: true,
                        element: dialog,
                    },
                }}
                anchorEl={dialog}
                open={dialog}
                onClose={()=>setDialog(false)}

            >
                <div className={"step-flat content"}>
                    <IconButton className={"exit"} onClick={()=>setDialog(false)}>X</IconButton>

                    <a href={`/interventions/${pin.id}`} target={'blank'}> {pin.devis.site.name}</a>
                    <nav>
                        <Tooltip title={text.SUPPLY_DISPLAY}>
                            <span className={"appro"} onClick={()=>setModal("appro")}/>
                        </Tooltip>
                        <Tooltip title={text.ESTIMATED_TIME}>
                        <span className={"time"} onClick={()=>setModal("time")}/>
                        </Tooltip>
                        <Tooltip title={text.CONTACTS}>
                            <span className={"contact"} onClick={()=>setModal("contact")}/>
                        </Tooltip>
                        <Tooltip title={text.REMOVE_STEP}>
                        <span className={"delete"} onClick={()=>onRemove(event)}/></Tooltip>
                    </nav>
                    <span className={"date-intervention"}>
                    {new Date(event.start).toLocaleTimeString()} à {new Date(event.endEvent).toLocaleTimeString()}
                </span>
                    <Modal open={modal} onClose={()=>setModal(null)}>
                        {modal==="appro"&&<EditSupply id={pin.id} />}
                        {modal==="contact"&&<InteractionClient id={pin.id} />}
                        {modal==="time"&&<TimeSelector onSave={handleTimeChange} />}
                    </Modal>

                </div>


            </Popper>
        </Fragment>)
}