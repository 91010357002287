import "./loading.css";
import LoadingSVG from "../images/LoadingSVG.svg";
import React, {Fragment} from "react";

export default function Loading({length}){
    return(
        <Fragment>
            <tbody className="loadingTable">
            <img className={"loading-svg"} src={LoadingSVG}/>

            {new Array(20).fill(1).map(tr=>
                                        <tr>
                                            {new Array(length).fill(1).map(t=>
                                                <td><center>
                                                    {Math.random()>.5?<span className={Math.random()>.5?"sub-temp":"sub-temp-three"}/>:""}
                                                    {Math.random()>.3?<span className={Math.random()>.5?"sub-temp":"sub-temp-three"}/>:""}</center>
                                                </td>)}
                                        </tr>)}


            </tbody>

        </Fragment>
    )
}