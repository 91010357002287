import React, {useState} from "react";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import { text } from "../util/constants";

export default function SearchTeam({defaultValue, onSave}) {
    console.log(defaultValue)
    const [teamList, setTeamList] = useState([])
    const [team, setTeam] = useState(defaultValue)

    let api = new IbatApi();

    let loadOptions=(inptv)=>{
        return api.getTeams(inptv).then(resp => {
            let map = resp.sort((a, b)=>{
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name){
                    return 1
                }
                return 0
            }).map(s => ({
                label: s.name,
                id: s.id,
                value:s.id,
                name: s.name
            }))
            setTeamList(map)
            return map
        })
    }




    function handleTeam(data){
        setTeam({value:data.id,label:data.name})
        onSave(data)
    }
    function handleSave() {
        console.log(team)
        onSave(team)
    }

    function handleInputChange(d){
        loadOptions(d)
    }

    function handleChange(d){
        onSave({id:d.value,name:d.label})
    }

    return (
        <div>
            <Select
                classNamePrefix={"search"}
                onInputChange={handleInputChange}
                placeholder={text.TEAM_SEARCH}
                options={teamList}
                onChange={handleChange}
                value={team}
            />
        </div>
    )
}
