import React, {Fragment, useState} from "react"
import IconButton from "@material-ui/core/IconButton";
import OpenFormIcon from "../../images/new-interv.png"
import AddRequestIcon from "../../images/add_request.png"
import "../style/InterventionFormlStyle.css"
import IbatApi from "../../utils/IbatApi";
import "../style/style.css"
import CreateDevis from "./CreateDevis";
import Modal from "react-responsive-modal";
import constants from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import {isSpecialIntervention} from "../../helpers/interventionManager";

export default function InterventionForm({onSaveSucceed, site, forRequest, requestId}) {
    const default_state={
        devis: {clientIdBCommande: ""},
        intervention:{}
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [state, setState] = useState(default_state)
    const [show, setShow] = useState(false)
    const [isSavable, setIsSavable] = useState(false)
    const api = new IbatApi()
    const mySiret = api.getTokenData()?.siret

    const requestMode = mySiret || forRequest




    function handleSave() {
        let intervention= {
            etat: state.intervention !== 0 && state.intervention.team && state.intervention.team.name !== "" ? constants.SCHEDULED : constants.SEND,// todo: ajouter environement variable
            idSecteur: state.intervention.secteur?state.intervention.secteur:null,
            idTypeIntervention: state.intervention.type,
            teamId: state.intervention.team?state.intervention.team.id:null,
            dateIntervention: state.intervention.intervention?new Date(state.intervention.intervention).getTime():0,
            jobTypeId: state.intervention.jobTypeId,
            estimateDuration:state.intervention.estimateDuration?state.intervention.estimateDuration:constants.DEFAULT_DURATION,
            dateEndIntervention: new Date(new Date(state.intervention.intervention).getTime()+state.intervention.dateEndIntervention).getTime()
        }

        let myUserId = api.getMyUserId();
        let devisApi = {
            ...state.devis,
            acompte: state.acompte,
            clientIdBCommande: state.clientIdBCommande,
            montant: state.montant,
            clientId: state.clientId,
            companyId: state.companyId,
            myUserId: myUserId ,
            comments: state.comments,
            dateReception: new Date().getTime(),
            documents: state.documents?state.documents:[],
            devisData:state.devisData?state.devisData:[],
            supplyMachinery:state.supplyMachinery?state.supplyMachinery:[],
            supplyDocument:state.supplyDocument?state.supplyDocument:[],
            supplyAdminDocument:state.supplyAdminDocument?state.supplyAdminDocument:[],
            intervention: {...intervention}
        }


        if (requestMode){
            api.createRequest(state.site.id, devisApi)
                .then(resp=>{
                    setState(default_state);
                    setIsSavable(false)
                    setShow(false)
                    onSaveSucceed()
                    window.open(`/request/${resp.id}`, "_blank")
                })
                .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
                .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
        else if (requestId) {
            api.createCommande(state.site.id, requestId, devisApi).then(resp => {
                setState(default_state);
                setIsSavable(false)
                setShow(false)
                onSaveSucceed()
                let idDevis = resp.devis.sort((a,b)=> a.dateReception>b.dateReception ? 1 : -1).at(-1).id
                console.log(idDevis)
                window.open(`/devis/${idDevis}`, "_blank")
                // window.open(`/devis/${resp.id}`, "_blank")
            })
                .then(r => {
                    onSaveSucceed(r)
                    enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
                })
                .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
        else  {
            api.creatIntervention(state.site.id, devisApi).then(resp => {
                setState(default_state);
                setIsSavable(false)
                setShow(false)
                onSaveSucceed(resp)
                window.open(`/devis/${resp.id}`, "_blank")
            })
                .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
                .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
    }

    function handleCancel() {
        setState(default_state)
        setShow(false)
    }

    function handleSavable(data) {
        if(!isSpecialIntervention(data.intervention.type)){
            setIsSavable(data.siteId && data.intervention.type && (data.intervention.jobTypeId || requestMode) && (requestMode ? data.clientIdBCommande && data.clientIdBCommande.trim() !== '' : true) && (requestMode || data.companyId))
            return
        }
        setIsSavable(  data.siteId
            && data.intervention.type
            && data.intervention.secteur
            && data.intervention.team
            && data.intervention.intervention
            && data.intervention.estimateDuration!==null
        )

    }

    function handleData(data) {
        setState(data)
        handleSavable(data)
    }

    return (
        <Fragment>
            <Tooltip title={text.TOOLTIP_CREATE_COMMAND}>
                <IconButton onClick={() => {setShow(true)}}>
                    <img src={forRequest ? AddRequestIcon: OpenFormIcon}/>
                </IconButton>
            </Tooltip>

            <Modal open={show} onClose={()=>setShow(false)}>
                <div className={"commande-form"}>
                    <div className={"commande-head"}>
                        <h2>{requestMode ? text.H2_CREATION_REQUEST : text.H2_CREATION_COMMAND}</h2>
                    </div>
                    <CreateDevis onChange={handleData} site={{...site}} mySiret={mySiret} requestMode={requestMode}/>

                    <IconButton onClick={isSavable?() => {handleSave()}:() => {setShow(false)}}>
                        <div>{isSavable?<IconButton className={"validation-button"}>{text.CREATE}</IconButton>:<IconButton className={"cancel-button"}>{text.CANCEL}</IconButton>} </div>
                    </IconButton>

                </div>
            </Modal>
        </Fragment>
    )
}