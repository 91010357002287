import "../style/UpdateSiteClient.css"
import {text} from "../util/constants";
import React, {useState} from "react";
import {IconButton} from "@material-ui/core";

export default function UpdateSiteClient({siret, siteClient, onSave, onCancel}) {
    const [state, setState] = useState({...siteClient})

    function handleChange(e){
        setState({...state, [e.target.id]: e.target.value})
    }

    return(
        <div className={"updateSiteClient"}>
            <h2>Modifier/Ajouter des informations internes</h2>
            <label>Référence interne du site</label>
            <input type={"text"} id={"internal_reference"} name={"internal_reference"} defaultValue={state.internal_reference} placeholder={"site_1598"} onChange={handleChange}/>
            <label>Secteur</label>
            <input type={"text"} id={"internal_secteur"} name={"internal_secteur"} defaultValue={state.internal_secteur} placeholder={"secteur7"} onChange={handleChange}/>
            <label>Manager</label>
            <input type={"text"} id={"manager"} name={"manager"} defaultValue={state.manager} placeholder={"Eric Dupont"} onChange={handleChange}/>
            <label>Siret</label>
            <input type={"text"} id={"siret"} name={"siret"} defaultValue={state.siret?? siret} placeholder={"51580568600013"} onChange={handleChange}/>
            <div className={"action-btn"}>
                <IconButton className={"cancel-button"} onClick={onCancel}>{text.CANCEL}</IconButton>
                <IconButton className={"validation-button"} onClick={()=>onSave({...state})}>{text.SAVE}</IconButton>
            </div>
        </div>
    )
}