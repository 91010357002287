import GoogleMap from "google-map-react";
import Pin from "../components/util/Pin";
import React, {useRef, useState} from "react";
import constants from "../components/util/constants";
import "./PlanningStyle.css"
import {IconButton} from "@material-ui/core";
import SearchTeam from "../components/forms/SearchTeam";
import IbatApi from "../utils/IbatApi";
import Modal from "react-responsive-modal";
import InteractionClient from "../components/bean/InteractionClient";
import StepContainer, {timeToDate} from "../step/StepContainer";
import {DndProvider} from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import {useSnackbar} from "notistack";
import { text } from "../components/util/constants";
import {isModifiable, getPinColor, getShape} from "../helpers/interventionManager";
import LoadingSVG from "../images/LoadingSVG.svg";
import Calendar from "./Calendar";
import PlanningMap from "../planning-map/PlanningMap";

export function Planning({team}){
    const {enqueueSnackbar,closeSnackbar}=useSnackbar()
    const [plannedIntervention,setPlannedIntervention]=useState([])
    const [maps,setMaps]=useState()
    const [map,setMap]=useState()
    const [loading,setLoading]=useState(false)
    const [state,setState]=useState(team?{team:team}: {})
    const [hovered,setHovered]=useState()
    const calendarRef=useRef()
    const mapRef=useRef()

    const api=new IbatApi()

    function getNeededIntervention(teamId,begin,end){
        calendarRef.current.getEvents(teamId,begin,end)
        mapRef.current.getinterventions(teamId,begin,end)
    }

    function addEvent(event){
        // api.getEvent(event.intervention.event.id)
        //     .then(headEvent=>{
        //         if(headEvent.start>=event.start){
        //             event.id=headEvent.id
        //         }
        //         return event
        //     },error=>event).then(event=>{
                calendarRef.current.addEvent(event)
        // })
    }

    function handleApiLoaded(map, maps) {
        setMaps(new maps.DistanceMatrixService())
        setMap(maps)
    }

    function getTimeBetween(originPoints,destinationPoints){
        return new Promise(resolve=> {
            const origin = originPoints.map(originPoint => ({
                lng: originPoint.devis.site.longitude,
                lat: originPoint.devis.site.latitude
            }))
            const destination = destinationPoints.map(destinationPoint => ({
                lng: destinationPoint.devis.site.longitude,
                lat: destinationPoint.devis.site.latitude
            }))
            maps.getDistanceMatrix(
                {
                    origins: [origin],
                    destinations: [destination],
                    travelMode: map.TravelMode.DRIVING,
                    unitSystem: map.UnitSystem.METRIC,
                }, (response, status) => {
                    if (status !== 'OK') {
                        console.log(status)
                        resolve(0)
                    }
                    console.log("resp",response)
                    resolve(response.rows.map((element,index)=>element.elements[index]))
                })
        })
    }

    function updateTravelTime(copy){
        setLoading(true)
        let find = copy.find(i=>!i.devis.site.latitude || !i.devis.site.longitude);
        //todo attention ajouter dans les traduction file
        if (find){
            alert(`l'intervention sur le site ${find.devis.site.name} pause probleme`)
            setLoading(false)
            return
        }
        let origins=copy.slice(0,copy.length-1)
        let destinations=copy.slice(1)
        return getTimeBetween(origins,destinations)
            .then(elems=>{
                return elems.map(elem=>elem.duration.value*constants.S1)
            })
            .then( times=>times.forEach((traveltimeCorrection,originIndex)=>{
                let destinationindex=originIndex+1
                let boolean = copy[destinationindex].dateIntervention-copy[originIndex].dateIntervention-copy[originIndex].estimateDuration*constants.S1<traveltimeCorrection;
                    if(boolean){
                        copy[destinationindex].dateIntervention=copy[originIndex].dateIntervention+copy[originIndex].estimateDuration*constants.S1+traveltimeCorrection
                    }
                })
            ).then(()=>setLoading(false))


    }

    function handleDate({target}) {
        let value = target.value;
        let year=parseInt(value.split('-')[0])
        let week=(parseInt(value.slice(-2)))
        let begin=new Date(year,0,7*(week-1)+4).getTime()
        let end=new Date(year,0,7*(week-1)+10).getTime()
        setState({...state,begin:begin,end:end})
    }

    function handleTeam(team) {
        setState({...state,team:team})
    }

    function saveIntervention(){
        calendarRef.current.save()
    }

    function importIntervention() {
        if (!state.begin || !state.end){
            alert(text.ALERT_SELECT_DATE)
            return
        }
        if (!state.team){
            alert(text.SELECT_TEAM)
            return
        }
        getNeededIntervention(state.team.id,state.begin,state.end)
    }

    function handleTravelTime() {
        calendarRef.current.travelTime()
        // updateTravelTime(plannedIntervention.sort((i1,i2)=>i1.dateIntervention-i2.dateIntervention))
        //
        //     .then(()=>enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"}))
        //     .catch(e=>{
        //         console.log(e)
        //         enqueueSnackbar(text.TRAVEL_TIME_ERROR, {variant: "error"})
        //         setLoading(false)
        //     })
    }

    // function handleTimeChange(idIntv,newTime,partition){
    //     if (partition){
    //         return
    //     }
    //     let updatedList =[...plannedIntervention]
    //     let i=updatedList.find(intv=>intv.id===idIntv)
    //     i.estimateDuration=newTime
    //     setPlannedIntervention(updatedList)
    // }



    return(
    <div className={"planificator"}>
        <div className={"planningHead"}>
          <div className={"form"}>
            <input type={"week"} id={"week"} name={"week"} onChange={handleDate}/>
             <div className={"team-selector"}>
                <SearchTeam defaultValue={team && {value:team.id,label:team.name}} onSave={handleTeam}/>
            </div>
              <div>
                <IconButton className={"refresh-button"} onClick={importIntervention}> {text.REFRESH} </IconButton>
             </div>
          </div>
        <div className={"travelTimeButton"}>{loading ?<img className={"LoadingSVG"} src={LoadingSVG}/>:<IconButton className={"traveltime-button"} onClick={handleTravelTime}>{text.TRAVEL_TIME}</IconButton>}</div>
        <div className={"savePlanning"}><IconButton className={"validation-button"} onClick={saveIntervention}>{text.SAVE}</IconButton></div>
    </div>

        <div className={"planning"}>
            <Calendar
                begin={state.begin}
                end={state.end}
                team={state.team}
                events={[]}
                maps={maps}
                map={map}
                onHover={id=>setHovered(id)}
                ref={calendarRef}
            />

            <PlanningMap
                hovered={hovered}
                handleAddPin={addEvent}
                onApiLoaded={handleApiLoaded}
                ref={mapRef}/>

        </div>
    </div>

    )
}