import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import {text} from "../components/util/constants";
import BillIcon from "../images/new-devis.png";
import DocumentLoader from "../components/util/DocumentLoader";

export default function EditEstimate({id}) {
    const {enqueueSnackbar} = useSnackbar();
    const [estimate] = useState("");
    const [quotations, setQuotations] = useState(null);

    let api = new IbatApi();

    useEffect(() => {
        if (quotations !== null) {
            return

        }
        api.getInterventionQuotation(id)
            .then(r => r.length > 0 && setQuotations(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    })


    return (<div className={"editIntervention flex-column primary-section"}>
        <div className={"img-container"}>
            <img alt={""} src={BillIcon}/>
        </div>
        {
            quotations &&
            <div className={"secondary-section"}>
                <h2>Devis existants</h2>
                {quotations?.map((q, idx) => <div className={"flex-row"} key={idx}>
                    {q.odooPdfId && <DocumentLoader name={q.odooId} type={"quotation"}/>}
                    <p>{q.odooRef}</p>
                </div>)}
            </div>
        }
    </div>
    )
}