import React, {useEffect, useState} from "react"
import SearchTeam from "./SearchTeam";
import "../style/EditInterventionStyle.css"
import IbatApi from "../../utils/IbatApi";
import EditInterventionTeamIcon from "../../images/team-maj.png";
import {IconButton} from "@material-ui/core";
import constants, { text } from "../util/constants";
import {useSnackbar} from "notistack";

export default function EditInterventionTeam({id, update}) {
    let api = new IbatApi();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [state, setState] = useState({team: {id: "", name: ""},
    })

    function handleTeam(data) {
        setState({...state, team: data})
    }

    function updateTeam() {
        api.setInterventionTeam(id, state.team.id).then(r => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }
    function deleteTeam() {
        api.removeInterventionTeam(id).then(r => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <div className={"editIntervention"}>
            <div className={"img-container"}>
                <img src={EditInterventionTeamIcon}/>
            </div>
            <div className={"container"}>
                <div>
                    <h2>{text.SELECT_TEAM}</h2>
                    <div className={"selecTeam"}>
                    <SearchTeam onSave={handleTeam} value={state.team}/>
                    <IconButton className={"cancel-button"} onClick={deleteTeam}>{text.UNPROGRAM_TEAM}</IconButton></div>
                </div>
                <IconButton className={"validation-button"} onClick={updateTeam}>{text.SAVE}</IconButton>

            </div>

        </div>

    )
}