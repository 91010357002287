import React, {useEffect, useState} from "react";
import OperationPicture from "./OperationPicture";
import IbatApi from "../utils/IbatApi";
let api =new IbatApi()

export default function FamilyPicture({pictures,onChangePictureVisible,onCaroussel,index,depot}){

    const [name,setName] =useState()
    useEffect(()=>{
        getTaken(pictures.find(elem=>elem.motherId===null).id)
    })

    function getTaken(uuid) {
        depot && api.getTaken(uuid).then(data=>data.type && setName(data.type.name?data.type.name:data.type))
    }

    return(
        <div className={"family"} >
            {depot && name}
            {pictures.sort((p1,p2)=>p1.date-p2.date).map((p,idx)=>
                <div key={p.id} className={"pic"}>
                    <span className={"index"}>{index}{pictures.length>1&&String.fromCharCode(idx+97)}</span>
                    <OperationPicture filePath={p.filePath}
                                      id={p.id}
                                      visible={p.visible}
                                      layer={p.layer}
                                      visibleStyleClass={"size"}
                                      onRightClick={onChangePictureVisible}
                                      onClick={onCaroussel} />
                </div>)}
        </div>
    )
}
