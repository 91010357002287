import IbatApi from "../../utils/IbatApi";
import React, {useEffect, useState} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import  BinIcon from "../../images/delete.png"
import constants, {text} from "../util/constants";
import Select from "react-select";
import {useSnackbar} from "notistack";
import SearchImg from "../../images/search-icon.png";
import Modal from "react-responsive-modal";

const api=new IbatApi()

export function UserEditor({id, username, userId, companyId, enabled, authorities, accountNonExpired, accountNonLocked, credentialsNonExpired}){
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [state,setState]=useState({
            id:id,
            username:username,
            password:"",
            userId:userId,
            companyId:companyId,
            enabled:enabled,
            authorities:authorities,
            accountNonExpired:accountNonExpired,
            accountNonLocked:accountNonLocked,
            credentialsNonExpired:credentialsNonExpired
        }
    )
    useEffect(() => {
        setState()
        console.log(state)
        console.log(id)
    }, [])
    const [selectedAuth,setSelectedAuth]=useState(authorities?.map(a=>({label:a,value:a})))



    //     function handleRole(e) {
    //     setState({...state,authorities:[{authority:e.target.value}] })
    // }

    function handlePassword(e) {
        setState({...state,password:e.target.value})
    }

    function update() {
        // console.log(state)
        api.updateUser(state).then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function deleteUser() {
        api.deleteUser(id).then(r => enqueueSnackbar(text.SNACKBAR_ERASED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleRole(ops) {
        setSelectedAuth(ops)
        setState({...state,authorities:ops.map(op=>(op.value))})
    }
    return <div>
        <input type={"text"} value={state.username} disabled/>
        <input type={"password"} value={state.password} onChange={handlePassword}/>
        <Select
            isMulti
            onChange={handleRole}
            placeholder={text.ENTER_ROLES}
            options={[{label:constants.TEAM,value:constants.TEAM},
                {label:constants.RT,value:constants.RT},
                {label:constants.GESTIONNAIRE,value:constants.GESTIONNAIRE},
                {label:constants.COMPTA,value:constants.COMPTA},
                {label:constants.ADMIN,value:constants.ADMIN},
                {label:constants.PERMISSION_AM,value:constants.PERMISSION_AM},
                {label:constants.PERMISSION_BILL,value:constants.PERMISSION_BILL},
                {label:constants.PERMISSION_CRE,value:constants.PERMISSION_CRE},
                {label:constants.PERMISSION_TRE,value:constants.PERMISSION_TRE},
                {label:constants.PERMISSION_EC,value:constants.PERMISSION_EC},
                {label:constants.PERMISSION_PAYMENT,value:constants.PERMISSION_PAYMENT},
                {label:constants.PERMISSION_PM,value:constants.PERMISSION_PM},
            ]}
            className={"select-fr.tegeo.ibat.core.operation"}
            value={selectedAuth}
        />
        <div className={"userOptions"}> <label htmlFor={state.id}>{text.ACTIVATED}</label><input id={state.id} type={"checkbox"} checked={state.enabled} disabled />
            <IconButton onClick={deleteUser}> <img alt={"supprimer"} src={BinIcon}/></IconButton>
            <IconButton onClick={update} className={"validation-button"}>{text.SAVE}</IconButton>
        </div>
    </div>
}
export function UserEditorMod({user, updateUserList}){
    const mySiret = api.getTokenData()['siret']
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [state,setState]=useState({
        id:user?.id,
        username:user?.username,
        password:"",
        siret: user?.siret ?? mySiret,
        userId:user?.userId,
        enabled:user?.enabled,
        accountNonExpired:user?.accountNonExpired,
        accountNonLocked:user?.accountNonLocked,
        credentialsNonExpired:user?.credentialsNonExpired
    })
    const [createRunning,setCreateRunning]=useState(false)
    const [noSiret, setNoSiret]=useState(false)
    useEffect(() => {
        setState({
            id:user?.id,
            username:user?.username,
            password:"",
            siret: user?.siret ?? mySiret,
            userId:user?.userId,
            enabled:user?.enabled,
            accountNonExpired:user?.accountNonExpired,
            accountNonLocked:user?.accountNonLocked,
            credentialsNonExpired:user?.credentialsNonExpired})
    }, [user])

    function handlePassword(e) {
        setState({...state,password:e.target.value})
    }
    function handleUsername(e) {
        setState({...state,username:e.target.value})
    }
    function handlePhoneNumber(e) {
        setState({...state,phoneNumber:e.target.value})
    }
    function handleSiret(e) {
        setState({...state,siret:e.target.value})
    }

    function handleSaveUser(){
        if (state.siret || mySiret){
            state?.id? update() : create()
        }
        else {
            setNoSiret(true)
        }

    }
    function createUser() {
        if(!state.password){
            enqueueSnackbar("Aucun mot de passe",{variant:"error"})
        }
        if(!state.username){
            enqueueSnackbar("Aucun nom d'utilisateur",{variant:"error"})
        }
        if (state.username && state.password && !createRunning){
            handleSaveUser()
        }
    }
    function updateUser() {
        handleSaveUser()
    }

    function create(){
        setCreateRunning(true)
        setNoSiret(false)
        api.creatUser(state)
            .then(r => {
                setCreateRunning(false)
                enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
                updateUserList()
            })
            .catch(e=>{
                setCreateRunning(false)
                enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"})
            })
    }
    function update() {
        setNoSiret(false)
        api.updateUser(state)
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }
    function deleteUser() {
        api.deleteUser(user.id)
            .then(r => {
                updateUserList()
                enqueueSnackbar(text.SNACKBAR_ERASED,{variant:"success"})
            })
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }


    return <div className={"modUser"}>
        <input placeholder={text.ENTER_USERNAME} type={"text"} value={state?.username?? ""} onChange={handleUsername} required={true} disabled={state?.id ?? false}/>
        <input placeholder={text.PASSWORD} type={"password"} autoComplete={"new-password"} value={state?.password} onChange={handlePassword} required={true}/>
        <input id={"phoneNumber"+state?.id} type={"text"} defaultValue={state?.phoneNumber} placeholder={"0612345789"} onChange={handlePhoneNumber}/>
        {!mySiret && <Tooltip title={"Cet utilisateur n'aura accès qu'aux informations du site en lien avec ce siret"}>
            <input id={"Siret" + state?.siret} type={"text"} defaultValue={state?.siret} placeholder={"51580568600013"}
                   onChange={handleSiret}/>
        </Tooltip>}

        <div className={"userOptions"}>
            <label htmlFor={state?.id}>{text.ACTIVATED}</label><input id={state?.id} type={"checkbox"} checked={state?.enabled} disabled />
            <IconButton onClick={deleteUser}> <img alt={"supprimer"} src={BinIcon}/></IconButton>
        </div>
        <IconButton onClick={state?.id? updateUser : createUser} className={"validation-button"}>{state?.id? text.SAVE : text.CREATE}</IconButton>

        <Modal open={noSiret} onClose={()=>setNoSiret(false)}>
            <div className={"alertNoSiret"}>
                <p>
                    Aucun siret n'est renseigné, cet utilisateur aura donc accès à tout les sites.
                </p>
                <div className={"inline"}>
                    <IconButton onClick={state?.id? update : create} className={"validation-button"}>{state?.id? text.SAVE : text.CREATE}</IconButton>
                    <IconButton onClick={()=>setNoSiret(false)} className={"cancel-button"}>{text.CANCEL}</IconButton>
                </div>

            </div>
        </Modal>
    </div>
}

function User({username}){
    return (
        <div className={"user"}>
            <div id={"iconImg"}/> <span>{username}</span> <div id={"settingsImg"}/>
        </div>
    )
}

export default function UserManager({allUser, handleClick}){
    const [users,setUsers]=useState(allUser)
    const [filteredUsers,setFilteredUsers]=useState([])
    const [selected,setSelected]=useState("")
    useEffect(()=>{
        // getAllUser()
        console.log(allUser)
        setUsers(allUser)
        setFilteredUsers(allUser)
    },[allUser])
    /*function getAllUser(){
        api.getAllUser().then(data=> {
            updateUserList(data)
        })
    }*/
    function filterUser(e) {
        const name = e.target.value;
        setFilteredUsers(users.filter(user => user.username.includes(name)))
    }
    return <div className={"user-manager"}>
        <h2>{text.USER_LIST}</h2>
        <img src={SearchImg} className={"searchIcon"}/><input type={"text"} placeholder={text.ENTER_USERNAME} defaultValue={null} onChange={filterUser}/>
        <ul>
            {filteredUsers && filteredUsers?.map(u=><li className={selected === u.id? "selected": ""} onClick={()=> {
                handleClick({...u})
                setSelected(u.id)
            }}><User {...u}/></li>)}
        </ul>
        <IconButton className={"validation-button"} onClick={()=>handleClick()}>{text.NEW_USER}</IconButton>
    </div>

}
