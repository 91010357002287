import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@material-ui/core";
import FileUploader from "../util/FileUploader";
import DeleteIcon from "../../images/delete.png"
import ReadyIcon from "../../images/ready.png";
import NotReadyIcon from "../../images/not-ready.png";
import DocumentLoader from "../util/DocumentLoader";
import constants from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {text} from "../util/constants";

export default function SupplyMachinery({id, supplyId, typeName, supplier, pickupLocation, pickupDay, isReady, issueDate, onChange,documents}) {
    const [state, setState] = useState(
        {
            type: typeName,
            issueDate: issueDate,
            supplyId: supplyId,
            id: id,
            supplier: supplier,
            pickupLocation: pickupLocation,
            pickupDay: pickupDay ? new Date(pickupDay).toLocaleDateString().split("/").reverse().reduce((s1, s2) => s1 + "-" + s2) : "",
            isReady: isReady,
            documents:documents?documents:[]
        })



    const api = new IbatApi();

    function sendMachinery(state) {
        const d = {
            type: state.type.id,
            issueDate: state.issueDate,
            supplier: state.supplier,
            pickUpLocation: state.pickupLocation,
            pickUpDay: state.pickupDay,
            ready: state.isReady
        }
        api.sendMachinery(state.id, d)
    }

    useEffect(() => {
        sendMachinery(state)
    }, [state])


    function handleMachinery(e) {

        if (e.target.type === "checkbox") {
            setState({...state, [e.target.name]: e.target.checked})
        } else {
            setState({...state, [e.target.name]: e.target.value})
        }
    }

    function deleteMachinery() {
        api.deleteMachinery(state.supplyId, state.id).then(r => onChange(r))
    }

    function handleFile(files){
        api.sendMachineryDocs(state.id,files).then(m=>setState({...state,...m}))
    }

    return (
        <div className={state.isReady ? "listItem ready" : "listItem"}>
            <Tooltip title={state.type.name}>
                <p>{state.type.name} {new Date(state.issueDate).toLocaleDateString()}</p>

            </Tooltip>
            <input type={"text"} name={"supplier"} value={state.supplier} placeholder={"Fournisseur"}
                   onChange={handleMachinery}/>
            <input type={"date"} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" id={state.id} name={"pickupDay"}
                   value={state.pickupDay} placeholder={"Date de livraison"} onChange={handleMachinery}/>
            {state.documents.length>0?state.documents.map(file=><DocumentLoader name={file}/>)
                :<FileUploader files={[]} onUpload={handleFile} id={state.id}/>}
            <Tooltip title={text.REMOVE}>
                <IconButton onClick={deleteMachinery}><img src={DeleteIcon}/></IconButton>
            </Tooltip>
            <label htmlFor={state.id}>
                <select  id={state.id} name={"isReady"} value={state.isReady} onChange={handleMachinery}>
                        <option value={constants.UNKNOWN}>- </option>
                        <option value={constants.IN_STOCK}>En Stock </option>
                        <option value={constants.SUPPLIER}>Fournisseur </option>
                        <option value={constants.ON_SITE}>Sur site </option>
                </select>
            <img src={state.isReady!=constants.UNKNOWN?ReadyIcon:NotReadyIcon}/></label>
        </div>
    )
}