import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import "../../table/interventionsStyle.css"
import Table from "../../table/Table";
import {IconButton} from "@material-ui/core";
import Modal from "react-responsive-modal";
import {text} from "../util/constants";
import AddCompany from "../../images/add_company.png";
import Tooltip from "@material-ui/core/Tooltip";
import NewServiceProviderSelect from "../forms/NewServiceProviderSelect";
import {useSnackbar} from "notistack";

const columns = [
  {id: "id", isFiltrable: true, label: text.NUMBER, type: "linkText", isChecked: true},
  {id: 'mail', isFiltrable: true, label: text.MAIL, type: "text", isChecked: true},
  {id: "name", isFiltrable: true, label: text.NAME, type: "text", isChecked: true},
  {id: "phone", isFiltrable: true, label: text.NUMBER, type: "text", isChecked: true},
  {id: "address", isFiltrable: true, label: text.ADDRESS, type: "text", isChecked: true},
  {id: "cp", isFiltrable: true, label: text.ZIPCODE, type: "text", isChecked: true},
  {id: "ville", isFiltrable: true, label: text.TOWN, type: "text", isChecked: true},
]

export default function Companies() {
  const [state, setState] = useState({
    columns: columns,
    companiesList: [],
    pageSize: 20,
    pageNumber: 0,
    count: 0,
    filters: {
      id: "",
      mail: "",
      name: "",
      phone: "",
      address: "",
      cp: "",
      ville: "",

    }
  })
  const [typing, setTyping] = useState(false)
  const [addProvider, setAddProvider] = useState(false)
  const {enqueueSnackbar,closeSnackbar}=useSnackbar()

  let api = new IbatApi();
  useEffect(() => {
    getCompanies()
  }, [typing, state.pageNumber, state.pageSize])

  useEffect(()=>{}, [state])

  const handleColumns = (event) => {
    let columns = state.columns
    let filter = {...state.filters}
    let find = columns.find(col => col.id === event.target.name);
    find.isChecked = event.target.checked
    if (find.isFiltrable) {
      filter[find.id] = ""
    }
    setState(state => ({...state, columns: columns, filters: filter}))
  }
  function getCompanies() {
    const result = api.getCompanies({
      ...state.filters,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize
    })
    result.then(res => {
      setState(state => ({...state, companiesList: res.content, count: res.totalElements}))
    })
  }
  function handleFilter(e) {
    let value = e.target.value
    let name = e.target.name
    setState(state => ({...state, filters: {...state.filters, [name]: value}}));
  }
  function handleChangePage(newPage) {
    setState(state => ({...state, pageNumber: newPage}))
  }
  function handleChangeRowsPerPage(event) {
    setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
  }
  function addNewServiceProvider(data){
    api.addServiceProvider(data.id)
        .then(resp => {
          enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"})
          setAddProvider(false)
          getCompanies()
        })
        .catch(e => {
          setAddProvider(false)
          enqueueSnackbar(e.message, {variant: "error"})
        })

  }

  return (
    <div>
      <Table
        onHandleFilter={handleFilter}
        onHandleChangePage={handleChangePage}
        onHandleChangeRowsPerPage={handleChangeRowsPerPage}
        onGetData={getCompanies}
        onHandleColumns={handleColumns}
        rowCount={state.count}
        filter={state.filters}
        onEndTyping={setTyping}
        pageNumber={state.pageNumber}
        pageSize={state.pageSize}
        columns={state.columns}
        list={state.companiesList}
        tabName={"company"}
      >

        <Tooltip title={"Ajouter un prestataire de service à la liste de vos collaborateurs."}>
          <IconButton onClick={()=>setAddProvider(true)}><img alt={"img"} src={AddCompany }/></IconButton>
        </Tooltip>
      </Table>

      <Modal open={addProvider} onClose={()=>setAddProvider(false)} >
        <NewServiceProviderSelect creation={true} onCancel={()=>setAddProvider(false)}  onSave={addNewServiceProvider}/>
      </Modal>
    </div>
  )
}
