import CreateIntervention from "./CreateIntervention";
import React, {Fragment, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CreateDevisIcon from"../../images/add-devis.png"
import SearchSite from "./SearchSite";
import {Modal} from "react-responsive-modal";
import SearchServiceProvider from "./SearchServiceProvider";
import DevisForm from "./DevisForm";
import { text } from "../util/constants";

export default function CreateDevis({onChange, site, mySiret, requestMode}){


    const default_state={
        acompte:0,
        comments:"",
        dateReception:0,
        montant:0,
        clientIdBCommande:"",
        devisData:[],
        documents:[],
        supplyDocument:[],
        supplyAdminDocument:[],
        supplyMachinery:[],
        clientId:0,
        myUserId:0,
        interventionApi:{},

        client:{},
        site:{},
        reception: 0,
        intervention:0
    }

    const [temp,setTemp]=useState(default_state)
    const [state, setState] = useState(default_state)
    const [devis,setDevis]=useState(false)

    function handleDevis(data) {
        setDevis(false)
        setState({...state, ...data})
        onChange({...state,  ...data})
    }

    function handleSite(data) {
        setState({...state, site: data,siteId:data.id})
        onChange({...state,site: data, siteId: data.id})

    }

    function handleInterventionData(data){
        setState({...state,intervention:data})
        onChange({...state,intervention:data})
    }

    function handleReception(e) {
        setState({...state, reception: e.target.value})
        onChange({...state, reception: e.target.value})
    }

    function handleServiceProvider(data) {
        setState({...state, companyId: data.id})
        onChange({...state, companyId: data.id})
    }

    function handleDevisFormSave(devis,metadata,comments,documents){
        let tempData={...temp,
            clientIdBCommande : devis.clientIdBCommande,
            documents : documents,
            acompte:devis.acompte,
            montant:devis.montant,
            devisData:metadata.devisData,
            supplyDocument: metadata.supplyDocument,
            supplyAdminDocument: metadata.supplyAdminDocument,
            supplyMachinery: metadata.supplyMachinery,
            comments:comments
        }
        setTemp(tempData)
    }


    return(
        <div className={"commande-data"}>
            <CreateIntervention requestMode={requestMode} mySiret={mySiret} onValid={handleInterventionData} onSiteChange={handleSite} site={{...site}}>

                <Fragment>
                    <div title={requestMode ? text.REQUEST_TITLE : text.BILL_CMD}>
                        <input type={"text"} defaultValue={state.clientIdBCommande} placeholder={requestMode ? "Demande de travaux" : text.REF_NUM}
                               onChange={(e) => {
                                   setState({...state, clientIdBCommande: e.target.value})
                                   onChange({...state, clientIdBCommande: e.target.value})
                               }}/>
                        {/*<IconButton onClick={() => setDevis(true)}><img src={CreateDevisIcon}/></IconButton>*/}
                    </div>
                    <Modal  open={devis} onClose={() => setDevis(false)}>
                        <DevisForm temp={temp} onSave={handleDevisFormSave} trigger={handleDevis}/>
                    </Modal>

                    {/*<div title={"Date"}>
                        <input className={"datetimeInput"} type={"datetime-local"} value={state.reception} onChange={handleReception}/>
                    </div>*/}
                    {(state.intervention.jobTypeId && !requestMode) && <div title={"Prestataire"} className={"cell commande-search"}>
                        <SearchServiceProvider onSave={handleServiceProvider} jobType={state.intervention.jobTypeId}/>
                    </div>}
                </Fragment>

            </CreateIntervention>
        </div>
    )
}