import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import IbatApi from "../../utils/IbatApi";
import "../style/SiteStyle.css"
import IconSite from "../../images/site-name.png"
import IconBuger from "../../images/history.png"
import UpdateSiteImage from "../forms/UpdateSiteImage";
import {IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {text} from "../util/constants";
import {getColor, isModifiable} from "../../helpers/interventionManager";
import InterventionForm from "../forms/InterventionForm";
import SiteIcon from "../../images/site-add.png"
import UpdateSiteClient from "../forms/UpdateSiteClient";
import Modal from "react-responsive-modal";
import {useSnackbar} from "notistack";
import ShareSiteOrDevis from "../forms/ShareSiteOrDevis";


export default function Site() {

  const {id} = useParams()

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [siteForm,setSiteForm]=useState(false)
  let api = new IbatApi();
  const mySiret = api.getTokenData()?.siret
  const [state, setState] = useState({})
  const [share, setShare] = useState(false)
  const [interventions, setinterventions] = useState([])
  const [siteClient, setSiteClient] = useState({id:null, internal_reference: null, internal_secteur: null, manager: null, siren: null, siret: null, site: null,
    archived: false
  })
  useEffect(() => {
    getData()
  }, [id])

  function handleSiteForm(){
    setSiteForm(!siteForm)
  }
  function saveSiteClient(data){
    console.log(data)
    setSiteClient({...siteClient, ...data})
    api.updateClientSite(id, data)
        .then(()=> enqueueSnackbar('Données enregistrées avec succès', {variant: "success"}))
        .catch(()=> enqueueSnackbar('Une erreur est survenue', {variant: "error"}))
    handleSiteForm()
  }
  function siteArchive(){
    api.siteArchive(id)
        .then(()=> {
          getData()
          enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
        })
        .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
  }

  function getData() {
    // api.getSite(id).then(data => setState({...state, ...data}))
    api.getSiteClient(id).then(data=> {
      setSiteClient({...siteClient, ...data})
      setState({...state, ...data.site})
    })
    api.getSiteIntervention(id).then(i => setinterventions(i))
  }


  function handleShare(data){
    api.shareSite(id, data.siren, data.siret??"")
        .then(r=> enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant:"success"}))
        .catch(r=> enqueueSnackbar(text.SNACKBAR_ERROR, {variant:"error"}))
  }

  return (
    <div className={"site-bean"}>
      <div className={"site"}>
        <h1><IconButton disabled={!state?.id || siteClient.archived} onClick={handleSiteForm} ><img src={IconSite}/></IconButton>{text.SITE} {id}</h1>
        <div>
          <div>
            <h2>{state.name ? state.name : text.NO_NAME}</h2>
            <p>{state.address}<br/> {state.cp} {state.ville} <br/> {state.status}<br/>{state.contactName}<br/>{state.siret}<br/>
              <ul>{state.contactPhone?.map(ph => <li>{ph}</li>)}</ul>
            </p>
          </div>
          {<UpdateSiteImage voie={state.address + " " + state.cp + " " + state.ville}
                            img={state.imageUrl ? state.imageUrl : null} siteId={id} latitude={state.latitude}
                            longitude={state.longitude} update={getData}/>}


          <div>
            {!mySiret &&
                <div>
                  <Tooltip title={"Partager ce site avec un tiers"}>
                    <IconButton disabled={!siteClient?.id} onClick={() => setShare(true)}>Share</IconButton>
                  </Tooltip>
                  <br />
                </div>
            }
            {(!siteClient?.archived && !mySiret) && <IconButton disabled={!state?.id} onClick={siteArchive}>Archiver ce site</IconButton>}
            {siteClient.archived && <div>Ce site est archivé</div>}
          </div>
        </div>
      </div>
      <div className={"interventions"}>
        <h3>
          {text.INTERVENTION_HISTORY} ({interventions.length > 0 ? interventions.length : 0})<br />

          <InterventionForm onSaveSucceed={getData} site={{...state}}/>
          <a>+ Nouvelle intervention</a>
        </h3>
        <div className={"table"}>
          <table>
            <thead>
            <tr>
              <th>{text.ID}</th>
              <th>{text.FILE_TYPE}</th>
              <th>{text.STATUS}</th>
              <th>{text.RECEPTION_DATE}</th>
              <th>{text.INTERVENTION_START}</th>
              <th>{text.SERVICE_PROVIDER}</th>
            </tr>
            </thead>
            <tbody>
            {interventions.map(
              (value, k) => {
                return (
                  <tr key={k}>
                    <td><a href={`/intervention/${value.id}`}> {value.id}</a></td>
                    <td>{value.type.name}</td>
                    <td>{text.STATUS_TRADUCTION[value.status]}</td>
                    <td>{value.devis.dateReception ? new Date(value.devis.dateReception).toLocaleDateString() : "-"}</td>
                    <td>{value.dateIntervention > 0 ? new Date(value.dateIntervention).toLocaleDateString() : "-"}</td>
                    <td><a href={`/company/${value.devis?.client?.id}`}>{value.devis?.company?.name}</a></td>
                  </tr>
                )
              }
            )}
            </tbody>

          </table>
        </div>
      </div>

      <Modal open={siteForm} onClose={handleSiteForm}>
        <UpdateSiteClient siret={state?.siret} siteClient={siteClient} onSave={saveSiteClient}
                          onCancel={handleSiteForm}/>
      </Modal>
      <Modal open={share} onClose={()=> setShare(false)}>
        <ShareSiteOrDevis siteOrDevis={"site"} share={handleShare}/>
      </Modal>
    </div>
  )
}
