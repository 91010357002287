import React, {Fragment, useState} from "react";
import constants from "../components/util/constants";
import CustomImage from "../components/util/CustomImage";
import IbatApi from "../utils/IbatApi";
const api=new IbatApi()
export default function OperationPicture({filePath,id,layer,visibleStyleClass,visible,width,height,onClick,onRightClick}){
    const [visibility,setVisibility]=useState(visible)
    function handleVisible(){
        api.hasRole(constants.PERMISSION_CRE) && setVisibility(!visibility)
        api.hasRole(constants.PERMISSION_CRE) && onRightClick&&onRightClick(visible,id)
    }

    return(
        <Fragment>
            <div onClick={()=>onClick(filePath)}>
                <CustomImage onRightClick={handleVisible} layerName={layer} width={width} height={height} name={filePath} className={!visibility?visibleStyleClass:""} />
            </div>
        </Fragment>

    )

}