import React from 'react'

export const CheckBox = props => {
    return (
        <div className={props.className} key={props.myKey}>
            <input name={props.id} onChange={props.handleCheckChieldElement} type="checkbox" checked={props.isChecked}
                   value={props.label}/>
            <label htmlFor={props.label}>{props.label}</label>
        </div>

    )
}

export default CheckBox
