import React, {useState} from "react";
import Modal from "react-responsive-modal";
import MapPointer from "../util/MapPointer";
import MajButton from "../../images/maj-button.png"
import {IconButton} from "@material-ui/core";
import IbatApi from "../../utils/IbatApi";
import ImageManquante from "../../images/map-add.png"
import CustomImage from "../util/CustomImage";
import constants from "../util/constants";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";

export default function UpdateSiteImage({img,siteId,latitude,longitude,update,voie}){
    const[open,setOpen]=useState(false)
    const[state,setState]=useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const api = new IbatApi();

    function handleModal() {
        setOpen(!open)
    }


    function handleLongLat(position){
        console.log(position)
        setState({...state,longitude:position.longitude,latitude:position.latitude,radius:position.radius})
    }

    function handleImage(image){
        setState({...state,imageUrl:image.imageUrl})
    }
    function isSavable() {
        return state.imageUrl!==""&& state.longitude!==""&&state.latitude!==""
    }
    function save(){
        if(isSavable()){
            api.updateSite(siteId,state).then(resp=>{
                update()
                handleModal()
            }).then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
                .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
        }
    }
    return(
    <div style={{cursor:"pointer"}}>
        {img?<CustomImage name={img} onClick={handleModal}/>:<div style={style}><img onClick={handleModal} src={ImageManquante}/></div>}

        <Modal open={api.hasRole(constants.PERMISSION_AM) && open} onClose={handleModal}>
            <MapPointer voie={voie} onLongLat={handleLongLat} onImageClick={handleImage}/>
            <IconButton onClick={save}><img src={MajButton}/></IconButton>
        </Modal>
    </div>
)
}
const style={

}