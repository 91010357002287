import "./product.css"
import React from "react";
import {text} from "../components/util/constants";
export default function Product({internalRef,productName,qty}){

    return(<div className={"product"}>
        <span name={text.REF}  className={"internalRef"}>
                {internalRef}
        </span>
        <span name={text.NAME} className={"name"}>{productName}</span>
        <span name={text.QUANTITY} className={"quantity"}>{qty}</span>
    </div>)
}