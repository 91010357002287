import React, {useState} from "react";
import "../style/pin-style.css"
import constants from "./constants";

export default function Pin({clickable,shape,text,pulse,data,onClick,color,onChildClick,onLeftClick,hovered,children,scale,cluster}) {
    const [selected,setSelected]=useState(false)
    const pinColor={background:color?color:"rgba(200,0,0,0.5)",width:Math.min(scale?25+scale:25,50),height:Math.min(scale?25+scale:25,50)}

    function viewData() {
        clickable && setSelected(!selected)
    }

    function leftClick() {
        onLeftClick?onLeftClick():setSelected(!selected)
    }


    function childrenClick() {
        onChildClick &&onChildClick()
    }


    return (
            <div className={`myPin ${cluster?"cluster":"nocluster"} `}>
                <div className={`pin bounce ${shape}`} style={pinColor} onContextMenu={leftClick} onClick={()=>{viewData(); onClick && onClick(data)}}>
                    <span>{text}</span>
                </div>
                <div onClick={childrenClick} className={!selected&&!hovered?"child":"child force"}>{children}</div>
                <div className={hovered?"pulse fast bounce-shadow":"pulse bounce-shadow"}>
                    {pulse && <div className={"wave"} style={{borderColor: pulse}}/>}
                </div>
            </div>
        );
}


