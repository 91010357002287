import Table from "../../table/Table";
import React, {useEffect, useState} from "react";
import {text} from "../util/constants";
import {useSnackbar} from "notistack";
import IbatApi from "../../utils/IbatApi";
import InterventionForm from "../forms/InterventionForm";

export default function Requests(){
    const columns = [
        {id: "id", label: text.ID, type: "linkText", isChecked: true, isFiltrable: true},
        {id: "requestDate", label: text.DATE, type: "linkText", isChecked: true, isFiltrable: false},
        {id: "title", label: text.ENTITLED, type: "text", isChecked: true, isFiltrable: true},
        {id: "site", label: text.SITE, type: "object", isChecked: true, isFiltrable: true},
        {id: "from", label: text.FROM, type: "text", isChecked: true, isFiltrable: false}
    ]
    let api = new IbatApi();
    const mySiret = api.getTokenData()?.siret

    const {enqueueSnackbar,closeSnackbar}=useSnackbar()
    const [state, setState] = useState({
        columns: columns,
        siteList: [],
        count: 0,
        pageNumber: 0,
        pageSize: 500,
        filters: {
            id: "",
            reference: "",
            name: "",
            adresse: "",
            cp: "",
            ville: "",
            siret: ""
        }
    })
    const [typing,setTyping]=useState(false)


    useEffect(() => {
        getRequests()
    }, [typing,state.pageNumber,state.pageSize])

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState(state => ({...state, filters: {...state.filters, [name]: value}}))
    }
    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState(state => ({...state, columns: columns, filters: filter, mapping: false}))
    }
    const handleChangePage = (newPage) => {
        setState(state => ({...state, pageNumber: newPage}))
    };
    const handleChangeRowsPerPage = (event) => {
        setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
    };

    function normalize(requests){
        return requests.map(r => {
            r.site = {id: r.siteClient.site.id, name: r.siteClient.site.name}
            r.requestDate = new Date(r.requestDate).toLocaleDateString()
            return r
        })
    }
    function getRequests() {
        const result = api.getRequestFiltered({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
        result
            .then(res => {
                setState(state => ({...state, siteList: normalize(res.content) ,count:res.totalElements}))
            })
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }

    return (
        <div>
            <Table list={state.siteList}
                   filter={state.filters}
                   columns={state.columns}
                   rowCount={state.count}
                   pageSize={state.pageSize}
                   pageNumber={state.pageNumber}
                   clickOnRow={true}
                   onEndTyping={setTyping}
                   onGetData={getRequests}
                   onHandleFilter={handleFilter}
                   onHandleColumns={handleColumns}
                   onHandleChangePage={handleChangePage}
                   onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   tabName={"request"}
                   setPageSize={false}
            >

                <InterventionForm onSaveSucceed={getRequests} forRequest={true}/>
                {/*{ !mySiret && <IconButton onClick={handleSiteForm} ><img src={SiteIcon}/></IconButton>}

                <Modal open={siteForm} onClose={handleSiteForm}>
                    <SearchSiteForClient onSave={handleSiteForm} name={""} onCancel={handleSiteForm}/>
                </Modal>*/}
            </Table>
        </div>
    )
}