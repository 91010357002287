import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import CreatableSelect from 'react-select/creatable';
import CreateSite from "./CreateSite";
import {Modal} from "react-responsive-modal";
import Select from "react-select";
import { text } from "../util/constants";


export default function SearchSite({onSave, preSelectionnedSite}) {
    const [siteList, setSiteList] = useState([])
    const [popup, setPopup] = useState(false)
    const [site, setSite] = useState()
    const [name, setName] = useState("")
    const [timer, setTimer] = useState()
    let api = new IbatApi();


    useEffect(()=>{
        if (!site && preSelectionnedSite.id){
            let s = mapDataSite(preSelectionnedSite)
            handleSite(s)
        }
    })

    function mapDataSite(s){
        return {
            label: s.name + " " + s.address+ " " + s.cp + " " + s.ville ,
            id: s.id,
            value:s.id,
            name: s.name + " - " + s.address + " " + s.cp + " " + s.ville
        }
    }
   function loadOptions(inptv){

        return api.findSite(inptv).then(resp => {
            let map = resp.map(s => ({
                label: s.name + " " + s.address+ " " + s.cp + " " + s.ville ,
                id: s.id,
                value:s.id,
                name: s.name + " - " + s.address + " " + s.cp + " " + s.ville
            }))
            console.log('MYDATA',inptv,resp,map)
            setSiteList(map)
            return map
        })
    }

    function handleInputChange(d){
        setName(d)
        if(d){
            timer && clearTimeout(timer)
            setTimer(setTimeout(()=>loadOptions(d),500))
        }


    }

    function handleCreate(d){
        setName(d)
        setPopup(true)
    }

    function handleSite(data){
        setSite({value:data.id,label:data.name})
        setPopup(false)
        onSave(data)
    }

    function handleCancel(){
        setSite(null)
        setPopup(false)

    }

    function handleChange(d){
        if(d!==null){
            onSave({id:d.value,name:d.label})
        }
    }
    return (
        <div className={"createSite"}>
            <Select
                classNamePrefix={"search"}
                isClearable
                placeholder={text.SITE_SEARCH}
                onChange={handleChange}
                onInputChange={handleInputChange}
                isLoading={popup}
                onCreateOption={handleCreate}
                value={site}
                formatCreateLabel={(input)=>`Créer ${input}`}
                noOptionsMessage={()=>text.NO_ENTRY_SELECTED}
                options={siteList}
                filterOption={e=>true}
            />
            <Modal open={popup} onClose={() => setPopup(false)}>
                <CreateSite name={name} onCancel={handleCancel} onSave={handleSite}/>
            </Modal>

        </div>
    )
}
