import {IconButton} from "@material-ui/core";
import {text} from "../components/util/constants";
import Modal from "react-responsive-modal";
import React, {Fragment, useState} from "react";
import Product from "../product/Product";
import WorkForce from "../work-force/WorkForce";
import "./work-force-displayer.css";

export default function WorkForceDisplayer({trigger,operations}){
    const [workForce, setWorkForce] = useState(false)
    const wf= operations.filter(op=>op.metadata).map(op=>op.metadata.points.map(p=>p.workForces)).flat(2)
    const prods= operations.filter(op=>op.metadata).map(op=>op.metadata.points.map(p=>p.productsAdded)).flat(2)


    return(
        <div className={"workForceButton"}>
            {(prods.length>0 || wf.length>0)&&<IconButton onClick={()=>setWorkForce(true)}>{trigger}</IconButton>}
            <Modal open={workForce} onClose={()=>setWorkForce(false)}>
                <ul>
                    {wf.map(d=><li><WorkForce {...d}/></li>)}
                </ul>
                <ul>
                    {prods.map(d=><li>
                        <Product {...d}/>
                    </li>)}
                </ul>
            </Modal>
        </div>

)
}