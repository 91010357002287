import React, {Fragment} from "react";
import {Modal} from "react-responsive-modal"
import "./row.css"


export default function Row({ligne, keyRow, columns, trigger, onClick, selected, tableName}) {

    const [openModal, setOpenModal] = React.useState(null)

    function handleModal(idModal) {
        setOpenModal(null)
        setOpenModal(idModal)
    }
    function handleUpdadte(){
        setOpenModal(null)
        trigger()
    }


    return (
        <tr key={keyRow} onClick={onClick} className={selected ? "row glow" : "row"}>
            {columns.filter(col => col.isChecked).map((col, k) => {
                if (col.type.includes("objectLink")) {
                    return (
                        <td key={k} title={ligne[col.id]["name"]}>
                            <Fragment>
                                    {ligne[col.id]["warning"]&&
                                <a href={col.id + "/" + ligne[col.id]["id"]} target={"_blank"} >
                                    <span>o</span>
                                </a>}
                                <a id={col.id} href={col.id + "/" + ligne[col.id]["id"]} target={"_blank"} >
                                    {ligne[col.id]["name"]}
                                </a>
                            </Fragment>
                        </td>
                    )
                }
                else if (col.type.includes("object")) {
                    return (
                        <td key={k} title={ligne[col.id]["name"]}>
                            {ligne[col.id]["warning"]&&<span></span>}
                            {ligne[col.id]["name"]}
                        </td>
                    )
                }
                else if (col.type.includes("linkText")) {
                    return (<td key={k} title={ligne[col.id]}>
                        <a id={col.id} href={tableName + "/" + ligne.id} target={"_blank"}>
                            <p>{ligne[col.id]}</p>
                        </a>
                    </td>)

                }
                else if (col.type.includes("dateText")) {
                    return (<td key={k} title={ligne[col.id]}>
                            <p>{ligne[col.id].name}</p>
                    </td>)

                }
                else if (col.type.includes("popup")) {
                    return (
                        <Fragment key={k}>
                            <td className={"editable"}
                                title={ligne[col.id] ? ligne[col.id].name : ligne.id + "" + col.id}
                                onClick={() => handleModal(ligne.id + "" + col.id)}>
                                <p>{col.icon ? <img src={col.icon}/>:ligne[col.id]["name"]}</p>
                                {ligne[col.id]&& ligne[col.id]["warning"]&&<span>o</span>}
                            </td>
                            <Modal open={openModal === ligne.id + "" + col.id}
                                   onClose={() => handleModal(null)}>
                                {
                                    React.createElement(col.popup, {id: ligne.id,idDev:ligne.devis.id,team:ligne.team ,update: handleUpdadte})
                                }
                            </Modal>
                        </Fragment>
                    )
                }
                else if (col.type.includes("insurance")){
                    return (<td key={k}>
                        {/*<a id={col.id} href={col.id + "/" + ligne.id} target={"_blank"}>
                            <p>{ligne["devis"][col.id].name}</p>
                        </a>*/}
                        <p>{ligne["devis"][col.id].name}</p>
                    </td>)
                }
                else {
                    return (<td key={k}>
                        <p>{ligne[col.id]}</p>
                    </td>)
                }
            })
            }
        </tr>
    )
}
