import CustomImage from "../util/CustomImage";
import NoImageIllust from "../../images/not-required-image.png";
import React from "react";

export default function Risk({id,answer,pictures,question}){
    return (
        <div className={"card"}>
            <span id={"question"}><b>{question}</b></span>
            <br/>

            {answer.map(ans=><div id={"reponse"}>-{ans}</div>)}
            <div id={"picture"}>{pictures.length > 0? pictures.map(p => <CustomImage name={p}/> ):<img src={NoImageIllust} />}</div>
        </div>
    )
}