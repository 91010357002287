import React, {Fragment, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {Redirect, Route, useHistory} from "react-router-dom"
import "../style/LoginFormStyle.css"
import Connexion from "../../images/connection.png"
import Check from "../../images/check-user.png"
import {IconButton} from "@material-ui/core";
import {text} from "./constants";

export default function AuthPage({refreshToken}){
    const [login,setLogin]=useState({username:"",password:""})
    let history = useHistory();

    let api=new IbatApi();

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setLogin({...login,[name]:value})
    }

    function handleLogin() {
        api.login(login).then(resp=> {
            resp && history.replace("/")
            refreshToken()
        })
        // api.login(login).then(resp=>resp && redirect())
    }

    function handleKey(e) {
        e.key==="Enter" && handleLogin();
    }

    return(
        <div className={"login"}>
            <img src={Connexion} />
            <Fragment>
                <h3>Se connecter</h3>
                <div>
                    <input type={"text"} name={"username"} placeholder={text.LOGIN_DETAILS} value={login.username} onKeyDown={handleKey} onChange={handleChange}/>
                    <input type={"password"} name={"password"} placeholder={text.PASSWORD} value={login.password} onKeyDown={handleKey} onChange={handleChange}/>
                    <a href={"/subscribe"}>{text.SUBSCRIBE}</a>

                </div>
                <IconButton className={"validation-button"} onClick={handleLogin}> {text.LOGIN} <img src={Check}/> </IconButton>
            </Fragment>

        </div>
    )
}