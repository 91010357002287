import React, {useEffect, useState} from "react"
import "../style/EditInterventionStyle.css"
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@material-ui/core";
import EditInterventionDateIcon from "../../images/date-interv.png"
import constants, { text } from "../util/constants";
import {useSnackbar} from "notistack";
import EditInterventionTeam from "./EditInterventionTeam";
import {Planning} from "../../planning/Planing";
export default function EditInterventionDate({id, team,update}) {
    
    const [date, setDate] = useState(0)
    let api = new IbatApi();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClick = () => {
    };
    function handleDate(e) {
        let value = e.target.value;
        console.log(value)
        setDate(value)
    }



    function updateDate() {
        api.setInterventionDate(id, new Date(date).getTime())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .then(()=>update())
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

    }

    function deleteDate() {
        api.removeInterventionDate(id)
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .then(()=>update())
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

    }

    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (team.id==-1?<EditInterventionTeam id={id} update={update}/>:<Planning team={team}/>
            // <div className={"editIntervention"}>
            //     <div className={"img-container"}>
            //         <img src={EditInterventionDateIcon}/>
            //     </div>
            //     <div className={"container"}>
            //         <h2>{text.INTERVENTION_START}</h2>
            //         <div className={"proDepro"}><input type={"datetime-local"} value={date} onChange={handleDate}/>
            //         <IconButton onClick={deleteDate} className={"cancel-button"}>{text.UNPROGRAM}</IconButton></div>
            //         <IconButton onClick={updateDate} className={"validation-button"}>{text.SAVE}</IconButton>
            //
            //     </div>
            // </div>

    )
}