import React, { useState} from "react";
import BinICon from "../../images/delete.png"
import ReadyIcon from "../../images/ready.png"
import NotReadyIcon from "../../images/not-ready.png"
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@material-ui/core";
import FileUploader from "../util/FileUploader";
import DocumentLoader from "../util/DocumentLoader";
import constants, { text } from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {EditText} from "react-edit-text";

export default function SupplyMaterial({type, supplyId, id, isReady, supplier, quantity, onChange,documents}) {
    const [state, setState] = useState({
        type: type,
        id: id,
        supplyId: supplyId,
        isReady: isReady,
        supplier: supplier,
        quantity: quantity,
        documents:documents?documents:[]
    })
    let api = new IbatApi();

    function sendMaterial() {
        let d = {
            type: state.type,
            ready: state.isReady,
            supplier: state.supplier,
            quantity: state.quantity,
        }
        api.sendMaterial(state.id, d).then(c => console.log(c))
    }

    function handleFile(files){
        api.sendMaterialDocs(state.id,files).then(MDocs=>setState({...state,...MDocs}))
    }


    function handleMaterial(e) {
        if (e.target.type === "checkbox") {
            setState(state => ({...state, [e.target.name]: e.target.checked}))
        }else if (e.target.type === "file") {
            setState(state => ({...state, [e.target.name]: e.target.files}))
        }else {
            setState(state => ({...state, [e.target.name]: e.target.value}))
        }
    }


    function deleteMaterial() {
        api.deleteMaterial(state.supplyId,state.id).then(r => onChange(r))
    }


    function handleType(value) {
        setState(state => ({...state, type: value}))

    }

    return (
        <div className={state.isReady ? "listItem ready" : "listItem"}>
            <EditText value={state.type} className={"materialName"} onChange={handleType} onBlur={sendMaterial} inline={true} id={"type"}/>

            <input type={"text"} name={"supplier"} placeholder={text.SUPPLIER} value={state.supplier}
                   onChange={handleMaterial} onBlur={sendMaterial}/>
            <input type={"number"} name={"quantity"} placeholder={text.QUANTITY} value={state.quantity}
                   onChange={handleMaterial} onBlur={sendMaterial}/>
            {state.documents.length>0?state.documents.map(file=><DocumentLoader name={file}/>)
                :<FileUploader files={[]} onUpload={handleFile} id={state.id}/>}
            <IconButton onClick={deleteMaterial}><img src={BinICon}/></IconButton>
            <label htmlFor={state.id}><select name={"isReady"} value={state.isReady} onChange={handleMaterial} onBlur={sendMaterial}>
                <option value={constants.UNKNOWN}>- </option>
                <option value={constants.IN_STOCK}>{text.AVAILABLE_PRODUCT} </option>
                <option value={constants.SUPPLIER}>{text.SUPPLIER}</option>
                <option value={constants.ON_SITE}>{text.SUPPLY_ON_SITE} </option>


            </select>
                <img src={state.isReady!=constants.UNKNOWN?ReadyIcon:NotReadyIcon}/></label>

        </div>
    )
}