import '../style/ShareSiteOrDevis.css'
import React, {useState} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import NewServiceProviderSelect from "./NewServiceProviderSelect";
import SearchClient from "./SearchClient";

export default function ShareSiteOrDevis({siteOrDevis, share}){
    const [clientOrProvider, setClientOrProvider] = useState()
    const itemToShare = siteOrDevis === "site" ? "ce site" : "cette commande"

    function handleShare(data){
        share(data)
    }

    return (
        <div className={"share"}>
            Avec qui souhaitez-vous partager {itemToShare} ?<br />

            <div className={"inline"}>
                <Tooltip title={"Dans le cadre d'une sous-traitance, un devis comparatif ..."}>
                    <IconButton onClick={()=>setClientOrProvider("provider")} className={`${clientOrProvider === "provider" ? "selected" : "choice-button "} validation-button`}>Un prestataire de service</IconButton>
                </Tooltip>
                <Tooltip title={"Dans le cadre d'un partage d'informations pour un bailleur, un syndic de co-pro, un potentiel acquéreur..."}>
                    <IconButton onClick={()=>setClientOrProvider("client")} className={`${clientOrProvider === "client" ? "selected" : "choice-button "} validation-button`}>Un tiers</IconButton>
                </Tooltip>
            </div>


            <div className={clientOrProvider ? "searchForShare" : ""}>
                {clientOrProvider === "provider" && <NewServiceProviderSelect onSave={handleShare} getAll={true} validationSentence={"Partager"}/> }
                {clientOrProvider === "client" && <SearchClient onSave={handleShare} validationSentence={"Partager"}/>}
            </div>
        </div>
    )
}