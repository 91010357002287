import CustomImage from "./CustomImage";
import "../style/CarousselStyle.css"
import React, {useState,useEffect} from "react";
import {IconButton} from "@material-ui/core";
import IbatApi from "../../utils/IbatApi";
import constants, { text } from "../util/constants";
import "../style/CarousselStyle.css"
function indexation(list){
    list.forEach(elem=> {
        let famindex = elem.index
        let fam = elem.family
        fam.forEach((elem, index) => {
            elem.index = `${famindex+1}${fam.length > 1 ? String.fromCharCode(index + 97) : ""}`
        })
    })
    return list.map(pf=>pf.family.sort((p1,p2)=>p1.date-p2.date))
        .flat(1)
    // .sort((elem1,elem2)=>elem1.index>elem2.index?1:-1)
}

export default function Caroussel({listOperation,firstFile,list,findComplementData,onPrevious,onNext,onExport}){
    const [exportArea,setExportArea]=useState()
    const [listPics,setlistPics]=useState(indexation(list))
    const api=new IbatApi()
    const [current,setCurrent]=useState(listPics.findIndex(elem=>elem.filePath===firstFile))
    const [currentComplement,setCurrentComplement]=useState()
    useEffect(()=>{
        if (!findComplementData){
            return
        }
        let complementData = findComplementData(listPics[current].filePath);
        setCurrentComplement(complementData)
    },[current])


    function next(){
        setCurrent(Math.min((current+1),listPics.length-1))
        onNext&&onNext()
    }
    function previous(){
        setCurrent(Math.max(current-1,0))
        onPrevious&&onPrevious()
    }
    function exportPicture(){
        if(!exportArea){
            alert(text.ADD_DESTINATION_TO_OPERATION)
            return;
        }
        api.addPicture(exportArea,[listPics[current].filePath]).then(()=>onExport(exportArea))
    }
    function handleExportOperation(e) {
        setExportArea(e.target.value)
    }
    return(
        <div className={"caroussel-container"}>
            <div className={"indexNumber"}>{listPics[current].index}</div>
            <div className={"caroussel"}>
                <IconButton onClick={previous}>‹</IconButton>
                <CustomImage name={listPics[current].filePath} layerName={currentComplement}/>
                <IconButton onClick={next}>›</IconButton>

            </div>

            <select className={"operationList"} onChange={handleExportOperation}>
                <option disabled selected >{text.EXPORT_TO}</option>
                {listOperation.map(op=><option key={op.id} value={op.id}>{op.typeName}</option>)}
            </select>
            <IconButton disabled={!api.hasRole(constants.PERMISSION_CRE)} className={"validation-button"} onClick={exportPicture}> {text.EXPORT} </IconButton>
        </div>
    )
}