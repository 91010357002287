import Product from "../product/Product";
import "./workforce.css"
import React from "react";
import {text} from "../components/util/constants";
import {IconButton} from "@material-ui/core";

export default function WorkForce({estimatedDuration,products}){

    return (<div className={"work-force"}>
        <span><IconButton src={"../images/info-tool-icon.png"} /></span>
        <div>
            <span className={"duration"}>{estimatedDuration}</span>
            <ul>
                {products.map(product=><li><Product {...product} /></li>)}
            </ul>
        </div>

    </div>)
}