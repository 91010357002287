import React, {Fragment, useEffect, useRef, useState} from "react";
import "../style/DevisFormStyle.css";
import IllustrationIcon from "../../images/doc.png";
import IconButton from "@material-ui/core/IconButton";
import ImportIcon from "../../images/import.png";
import IbatApi from "../../utils/IbatApi";
import Select from "react-select";
import constants, { text } from "../util/constants";


export default function DevisForm({temp,trigger,onSave}) {
    const [state, setState] = useState({clientIdBCommande: temp.clientIdBCommande,montant:temp.montant,acompte:temp.acompte})
    const [metadata,setMetadata]=useState({
        supplyDocument:[...temp.supplyDocument],
        supplyMachinery:[...temp.supplyMachinery],
        devisData:[...temp.devisData],
        supplyAdminDocument:[...temp.supplyAdminDocument]
    })
    const [comments, setComment] = useState(temp.comments)
    const [devisData, setDevisData] = useState([...temp.devisData])
    const [documents,setDocuments]=useState( {...temp.documents})

    const [importedDocuments, setImportedDocuments] = useState([])
    const [importedAdminDocuments, setImportedAdminDocuments] = useState([])
    const [importedMachineries, setImportedMachineries] = useState([])
    const api=new IbatApi()

    useEffect(()=>{
        getTypes()
    }, [])

    function getTypes() {
        api.getAdministrationTypesDocument().then(d => setImportedAdminDocuments(d.map(d=>({value:d.id,label:d.name}))))
        api.getConstructionTypesMachinery().then(d => setImportedMachineries(d.map(d=>({value:d.id,label:d.name}))))
        api.getContructionTypesDocument().then(d => setImportedDocuments(d.map(d=>({value:d.id,label:d.name}))))
    }

    function handleChange(e) {
        const name = e.target.name
        setState({
            ...state,
            [name]: e.target.value
        })
    }

    function handleFile(files,name) {
        console.log(documents)
        setDocuments({...documents,[name]:files})
    }

    function importDevis() {
        api.importDevis(state.clientIdBCommande).then(c=>{
            setState({...state,montant:c.montant})
            setDevisData([...c.devisData].reverse())
        })
    }

    function handleMetadataDocument(data) {
        setMetadata({...metadata,supplyDocument:data})
    }
    function handleMetadataAdminDocument(data) {
        setMetadata({...metadata,supplyAdminDocument:data})
    }

    function handleMetadataMachiery(data) {
        setMetadata({...metadata,supplyMachinery:data})
    }

    function handleComments(e) {
        const value=e.target.value
        setComment(value)
    }

    function save() {
        const devData={...state,
            devisData:devisData,
            supplyDocument:metadata.supplyDocument.map(d=>({type:d.value})),
            supplyMachinery:metadata.supplyMachinery.map(m=>({type:m.value})),
            supplyAdminDocument:metadata.supplyAdminDocument.map(d=>({type:d.value})),
            comments:comments,
        }
        console.log("DEVDATA",devData)
        trigger(devData)
        console.log(state,metadata,comments)
        onSave(state,metadata,comments,documents)

    }

    return (
    <div>
        <h2>{text.CMD_CREATION}</h2>
        <div className={"devisForm"}>
            <div className={"icon"}><img src={IllustrationIcon}/></div>
                <div className={"commande"}>

                    <input type={"text"} id={"id"} name={"clientIdBCommande"} value={state.clientIdBCommande} placeholder={text.REF_NUM} onChange={handleChange}/>
                    {api.getTokenData().companyId==constants.ABS_COMPANYID && <IconButton onClick={importDevis}><img src={ImportIcon}/></IconButton>}
                    <div>
                        <input type={"number"} id={"montant"} name={"montant"} placeholder={text.AMOUNT_CURRENCY} value={state.montant} onChange={handleChange}/>
                        <input type={"number"} id={"acompte"} name={"acompte"} placeholder={text.DEPOSIT_CURRENCY} value={state.acompte} onChange={handleChange}/>
                    </div>

                </div>
                <div className={"files"}>
                    <div>
                        <h3>{text.ATTACHED_DOC}</h3>
                        <div>
                            <Select onChange={handleMetadataAdminDocument}
                                    isMulti
                                    placeholder={text.ADMIN_DOC_LIST}
                                    options={importedAdminDocuments}
                                    value={metadata.supplyAdminDocument}
                            />
                        </div>
                    </div>

                </div>


                <div className={"comments"}>
                    <div>
                        <h3>{text.COMMENTS_AND_NOTES}</h3>
                    </div>
                    <div className={"container"}>
                        <textarea name={"comments"} value={comments} onChange={handleComments}/>
                    </div>

                </div>
                <div className={"document"}>
                    <h3>{text.DOCS}</h3>
                    <div>
                        <Select onChange={handleMetadataDocument}
                                isMulti
                                placeholder={text.WORK_DOCS_LIST}
                                options={importedDocuments}
                                value={metadata.supplyDocument}
                                />
                    </div>
                </div>
                <div className={"machineries"}>
                    <h3>{text.ENGINE}</h3>
                    <div>
                        <Select onChange={handleMetadataMachiery}
                                isMulti
                                placeholder={text.ENGINE_LIST}
                                options={importedMachineries}
                                value={metadata.supplyMachinery}
                        />
                    </div>
                </div>
                <div className={"devisData"}>
                    <h3>{text.QUOTE_ELEMENT}</h3>
                    <ul>
                        {devisData.map(d=><li>{d.name}</li>)}
                    </ul>
                </div>
            </div>
        <IconButton className={"validation-button"} onClick={save}>{text.ADD_BUTTON}</IconButton>
    </div>
    )

}