import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import SiteIcon from "../../images/site-add.png"
import Table from "../../table/Table";
import {IconButton} from "@material-ui/core";
import Modal from "react-responsive-modal";
import CreateSite from "../forms/CreateSite";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import SearchSiteForClient from "../forms/SearchSiteForClient";


const columns = [
    {id: "id", label: text.ID, type: "linkText", isChecked: true, isFiltrable: false},
    {id: "internal_reference", label: text.REFERENCE, type: "linkText", isChecked: true, isFiltrable: false},
    {id: "name", label: text.NAME, type: "text", isChecked: true, isFiltrable: true},
    {id: "address", label: text.ADDRESS, type: "text", isChecked: true, isFiltrable: true},
    {id: "cp", label: text.ZIPCODE, type: "text", isChecked: true, isFiltrable: true},
    {id: "ville", label: text.TOWN, type: "text", isChecked: true, isFiltrable: true},
    {id: "siret", label: text.SITE_SIRET, type: "text", isChecked: true, isFiltrable: true},
    // {id: "pays", label: text.COUNTRY, type: "text", isChecked: true, isFiltrable: true},
    // {id: "contactName", label: text.CONTACT_NAME, type: "text", isChecked: true, isFiltrable: false},
]
export default function Sites() {
    const {enqueueSnackbar,closeSnackbar}=useSnackbar()

    const [siteForm,setSiteForm]=useState(false)
    const [state, setState] = useState({
        columns: columns,
        siteList: [],
        count: 0,
        pageNumber: 0,
        pageSize: 500,
        filters: {
            id: "",
            reference: "",
            name: "",
            adresse: "",
            cp: "",
            ville: "",
            siret: ""
        }
    })
    const [typing,setTyping]=useState(false)
    let api = new IbatApi();
    const mySiret = api.getTokenData()?.siret
    useEffect(() => {
        getSites()
    }, [typing,state.pageNumber,state.pageSize])

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState(state => ({...state, filters: {...state.filters, [name]: value}}))
    }

    const handleChangePage = (newPage) => {
        setState(state => ({...state, pageNumber: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
    };

    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState(state => ({...state, columns: columns, filters: filter, mapping: false}))
    }


    function getSites() {
        const result = api.getSiteFilterd({
            ...state.filters,
            pageNumber: state.pageNumber,
            pageSize: state.pageSize
        })
        result
            .then(res => {
                console.log(res.content)
            setState(state => ({...state, siteList: res.content ,count:res.totalElements}))
        })
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }

    function handleSiteForm(){
        setSiteForm(!siteForm)
    }

    return (
        <div>
            <Table list={state.siteList}
                   filter={state.filters}
                   columns={state.columns}
                   rowCount={state.count}
                   pageSize={state.pageSize}
                   pageNumber={state.pageNumber}
                   clickOnRow={true}
                   onEndTyping={setTyping}
                   onGetData={getSites}
                   onHandleFilter={handleFilter}
                   onHandleColumns={handleColumns}
                   onHandleChangePage={handleChangePage}
                   onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   tabName={"site"}
                   setPageSize={false}
            >

                { !mySiret && <IconButton onClick={handleSiteForm} ><img src={SiteIcon}/></IconButton>}

                <Modal open={siteForm} onClose={handleSiteForm}>
                    <SearchSiteForClient onSave={handleSiteForm} name={""} onCancel={handleSiteForm}/>
                </Modal>
            </Table>
        </div>
    )
}
