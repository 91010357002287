import React, {Fragment, useEffect, useState} from 'react';
import IbatApi from "../../utils/IbatApi";
import "../style/SupplyStyle.css"
import SupplyDocument from "../bean/SupplyDocument";
import SupplyMachinery from "../bean/SupplyMachinery";
import SupplyMaterial from "../bean/SupplyMaterial";
import AddIcon from "../../images/button-create.png"
import MaterialIcon from "../../images/materiel.png"
import MachineryIcon from "../../images/nacelle.png"
import DocumentIcon from "../../images/newdoc.png"
import {IconButton} from "@material-ui/core";
import SupplyAdminDocument from "../bean/SupplyAdminDocument";
import constants from "../util/constants";
import { text } from "../util/constants";
import ImportIcon from "../../images/import.png";
import {useSnackbar} from "notistack";

export default function EditSupply({id,handleSupply}) {
    let api = new IbatApi();

    const [state, setState] = useState({
        id: id,
        document: {
            type: "",
            issueDate: "",
        },
        adminDocument: {
            type: "",
            issueDate: "",
        },
        machinery: {
            type: "",
            issueDate: ""
        },
        material: {
            type: "",
            quantity: "1",
        },
    })


    const [materials, setMaterials] = useState([])
    const [machineries, setMachineries] = useState([])
    const [machineriesType, setMachineriesType] = useState([])
    const [documents, setDocuments] = useState([])
    const [documentsType, setDocumentsType] = useState([])
    const [adminDocuments, setAdminDocuments] = useState([])
    const [aminDocumentsType, setAdminDocumentsType] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    useEffect(()=>{
        getTypes()
        },[])
    useEffect(() => {
        getSupply()
    }, [id])

    function getTypes() {
        api.getConstructionTypesMachinery().then(d => setMachineriesType(d))
        api.getContructionTypesDocument().then(d => setDocumentsType(d))
        api.getAdministrationTypesDocument().then(d => setAdminDocumentsType(d))
    }

    function getSupply() {
        return (handleSupply?handleSupply():api.getSupply(id))
            .then(a => {
            setState({...state, id: a.id,})
            updateTable(a.elements)

        })
    }

    function updateTable(elements){
        setMaterials(elements.filter(e=>e["@type"]===constants.MATERIAL_CLASS))
        setDocuments(elements.filter(e=>e["@type"]===constants.DOCUMENT_CLASS))
        setAdminDocuments(elements.filter(e=>e["@type"]===constants.ADMIN_DOC_CLASS))
        setMachineries(elements.filter(e=>e["@type"]===constants.MACHINERY_CLASS))
    }


    function addDocument() {
        const document = {
            type: state.document.type,
            issueDate: new Date(state.document.issueDate).getTime(),
        }
        api.addDocumentSupply(state.id, document).then(d => getSupply())
    }

    function addAdminDocument() {
        const document = {
            type: state.adminDocument.type,
            issueDate: new Date(state.adminDocument.issueDate).getTime(),
        }
        api.addAdminDocumentSupply(state.id, document).then(d =>getSupply())
    }

    function addMachinery() {
        const machinery = {
            type: state.machinery.type,
            issueDate: new Date(state.machinery.issueDate).getTime()
        }
        api.addMachinerySupply(state.id, machinery).then(d => getSupply())
    }

    function addMaterial() {
        const material = {
            type: state.material.type,
            quantity: state.material.quantity
        }
        api.addMaterialSupply(state.id,[material])
            .then(d=>getSupply()).then(state.material.type="")
    }

    function importDevis() {
        api.importDevis(state.material.type,)
            .then(c=> c.devisData.map(elem=>({type: elem.name, supplier: elem.supplier, quantity: elem.quantity,})))
            .then(materials=>api.addMaterialSupply(state.id,[...materials]))
            .then(d=>getSupply())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleMaterialCreate(e) {
        setState({...state, material: {...state.material, [e.target.name]: e.target.value}})
    }

    function handleDocumentCreate(e) {
        setState({...state, document: {...state.document, [e.target.name]: e.target.value}})
    }

    function handleAdminDocumentCreate(e) {
        setState({...state, adminDocument: {...state.adminDocument, [e.target.name]: e.target.value}})
    }


    function handleMachineryCreate(e) {
        setState({...state, machinery: {...state.machinery, [e.target.name]: e.target.value}})
    }

    if(!api.hasRole(constants.PERMISSION_AM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <div className={"supply"}>

            <div className={"adminDocument type"}>
                <div className={"img-container"}>
                    <img src={DocumentIcon}/>
                </div>
                <div className={"add-section"}>
                    <h2>{text.H2_ADMIN_DOCS}</h2>
                    <div className={"container"}>
                        <select required name={"type"} onChange={handleAdminDocumentCreate}>
                            <option disabled defaultValue={""} selected>{text.DOC_TYPE}</option>
                            {aminDocumentsType?.sort((d1,d2)=>d1.name.toLowerCase()>d2.name.toLowerCase()?1:-1).map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                        </select>
                        <input type={"date"} name={"issueDate"} value={state.adminDocument.issueDate}
                               pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                               onChange={handleAdminDocumentCreate}/>
                        <IconButton onClick={addAdminDocument} className={"validation-button"}>{text.ADD_BUTTON}</IconButton>
                    </div>
                </div>
                <ul>
                    {adminDocuments?.map((d, k) =>
                        <li key={d.id}><SupplyAdminDocument issueDate={d.issueDate} supplyId={state.id} typeName={d.type}
                                                       id={d.id} isReady={d.ready} receptionDate={d.receptionDate} {...d}
                                                       onChange={e=>updateTable(e.elements)}/></li>)}
                </ul>
            </div>

            <div className={"document type"}>
                <div className={"img-container"}>
                    <img src={DocumentIcon}/>
                </div>
                <div className={"add-section "}>
                    <h2>{text.DOCS}</h2>
                    <div className={"container"}>
                        <select required name={"type"} onChange={handleDocumentCreate}>
                            <option disabled defaultValue={""} selected>{text.DOC_TYPE}</option>
                            {documentsType.sort((d1,d2)=>d1.name.toLowerCase()>d2.name.toLowerCase()?1:-1).map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                        </select>
                        <input type={"date"} name={"issueDate"} value={state.document.issueDate}
                               pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                               onChange={handleDocumentCreate}/>

                        <IconButton onClick={addDocument} className={"validation-button"}>{text.ADD_BUTTON}</IconButton>
                    </div>
                </div>
                <ul>
                    {documents.map((d, k) =>
                        <li key={d.id}><SupplyDocument issueDate={d.issueDate} supplyId={state.id} typeName={d.type}
                                                    id={d.id} isReady={d.ready} receptionDate={d.receptionDate} {...d}
                                                    onChange={e=>updateTable(e.elements)}/></li>)}
                </ul>
            </div>


            <div className={"machinery type"}>
                <div className={"img-container"}>
                    <img src={MachineryIcon}/>
                </div>
                <div className={"add-section"}>
                    <h2>{text.ENGINES_AND_OTHER}</h2>
                    <div className={"container"}>
                        <select required name={"type"} onChange={handleMachineryCreate}>
                            <option disabled defaultValue={""} selected>{text.DEVICE_TYPE}</option>
                            {machineriesType.sort((d1,d2)=>d1.name.toLowerCase()>d2.name.toLowerCase()?1:-1).map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                        </select>
                        <input type={"date"} name={"issueDate"} value={state.machinery.issueDate}
                               pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" onChange={handleMachineryCreate}/>
                        <IconButton onClick={addMachinery} className={"validation-button"}>{text.ADD_BUTTON}</IconButton>
                    </div>
                </div>
                <ul>
                    {machineries.map((d, k) => <li key={d.id}>
                        <SupplyMachinery isReady={d.ready} id={d.id} supplyId={state.id} pickupDay={d.pickUpDay}
                                         pickupLocation={d.pickUpLocation} issueDate={d.issueDate} typeName={d.type}
                                         supplier={d.supplier} onChange={e=>updateTable(e.elements)} {...d}/>
                    </li>)}
                </ul>
            </div>


            <div className={"material type"}>
                <div className={"img-container"}>
                    <img src={MaterialIcon}/>
                </div>

                <div className={"add-section"}>
                    <h2>{text.EQUIPMENT}</h2>
                    <div className={"container"}>
                        <input type={"text"} value={state.material.type} name={"type"} placeholder={text.EQUIPMENT_NAME}
                               onChange={handleMaterialCreate}/>
                        <IconButton onClick={addMaterial} className={"validation-button"}>{text.ADD_BUTTON}</IconButton>
                        {api.getTokenData().companyId==constants.ABS_COMPANYID && <IconButton onClick={importDevis}><img src={ImportIcon}/></IconButton>}

                    </div>
                </div>
                    <ul>
                        {materials.map((d, k) => <li key={d.id}>
                            <SupplyMaterial type={d.type} supplyId={state.id} isReady={d.ready} supplier={d.supplier}
                                            id={d.id} quantity={d.quantity} onChange={e=>updateTable(e.elements)} {...d}/>
                        </li>)}
                    </ul>

            </div>

        </div>
    )
}