import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import { constants } from "../util/constants";


export default function JobTypeSelector({onChange, jobType}){
    let api = new IbatApi();
    const [jobTypeList, setJobTypeList] = useState()

    useEffect(() => {
        if (!jobTypeList){
            api.getJobTypes().then(jt => {
                setJobTypeList(jt.map(jt => {
                        return {
                            label: constants[jt.name],
                            value: jt.id
                        }
                    }
                ))
            })
        }
    }, [])

    return (
        <div>
            {jobTypeList && <div title={"Corps de métier"}>
                <select defaultValue={jobType} id={"jobTypeId"} required onChange={onChange} className={"jobTypeSelector"}>
                    <option disabled selected>Corps de métier</option>
                    {jobTypeList?.sort((a, b) => a?.label > b?.label ? 1 : -1)?.map(ut => <option
                        value={ut.value}>{ut.label}</option>)}
                </select>
            </div>}
        </div>
    )
}