import GoogleMap from "google-map-react";
import Pin from "./Pin";
import AddPinLogo from "../../images/pin-new.png";
import MapIcon from "../../images/map-illus.png";
import React, {useEffect, useState} from "react";
import {IconButton} from "@material-ui/core";
import PictureIcon from "../../images/new-capture.png";
import InfoToolIcon from "../../images/info-tool-icon.png"
import "../style/mapPointerStyle.css"
import SEARCHICON from "../../images/gmaps-search.png"
import Select from "react-select";
import {text} from "../util/constants";

export default function MapPointer({voie,onLongLat,onImageClick,onSiteSelected}){
    const [screenShot, setScreenshot] = useState(false)
    const [mapProps, setMapProps] = useState({})
    const [image, setImage] = useState("")
    const [adresse, setAdresse] = useState([])
    const [site, setSite] = useState({value:{latitude:0,longitude:0},label:voie})
    const [inputValue, setInput] = useState(voie)



    useEffect(()=>{
        const loadedVal=loadOptions(voie)
            if(loadedVal){
                loadedVal
                    .then(map=>map[0])
                    .then(data=> {
                        if (!data) {
                            alert(text.ADDRESS_NOT_FOUND)
                            return
                        }
                        setInput(data.label)
                        return setSite(data)
                    })
            }


    },[])



    const handleMapPropsChange = (e) => {
        setMapProps(mapProps => ({...mapProps, ...e}))
    }

    const setLongLat = (e) => {
        if(!mapProps.bounds){
            const data = {latitude: site.value.latitude,longitude: site.value.longitude, id: "+", radius:0}
            onLongLat({longitude: data.longitude, latitude: data.latitude, radius:0})
            return
        }
        const R = 6371000;
        const a1 = mapProps.bounds.nw.lat * Math.PI/180;
        const a2 = mapProps.center.lat * Math.PI/180;
        const Δφ = (mapProps.center.lat - mapProps.bounds.nw.lat) * Math.PI/180;
        const Δλ = (mapProps.center.lng - mapProps.bounds.nw.lng) * Math.PI/180;
        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(a1) * Math.cos(a2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c; // in metres
        const data = {latitude: e.lat, longitude: e.lng, id: "+", radius:d}
        setSite({...site,value: data})
        onLongLat({longitude: data.longitude, latitude: data.latitude, radius:d})

    }

    const generateStaticImage = (marker = false) => {
        let imageUrl
        if (site.value.latitude > 0) {
            const begin = !marker ? "https://maps.googleapis.com/maps/api/staticmap?center=" + site.value.latitude + "," + site.value.longitude + "&" :
                "https://maps.googleapis.com/maps/api/staticmap?markers=" + site.value.latitude + "," +site.value.longitude + "&"
            imageUrl = begin +
                "zoom=" + mapProps.zoom + "&" +
                "maptype=satellite&" +
                "size=" + mapProps.size.width + "x" + mapProps.size.height + "&" +
                "key="+process.env.REACT_APP_GOOGLE_API_KEY
        }
        setImage(imageUrl)
        setScreenshot(true)
        onImageClick && onImageClick({site:site,imageUrl: imageUrl})
    }

    const loadOptions=(voie)=>{
        if(typeof voie!="string" || !voie){
            return null
        }
        let location = `https://api-adresse.data.gouv.fr/search/?q=${voie}`
        return fetch(location).then(resp => resp.json()
            .then(jres => {
                setAdresse([])
                let voies = jres.features.map(obj => (
                    {
                        ...obj.geometry,
                        data:{
                            cp: obj.properties.postcode,
                            city: obj.properties.city,
                            adresse: obj.properties.name,
                            latitude:obj.geometry.coordinates[1],
                            longitude:obj.geometry.coordinates[0]
                        },
                        value:{
                            latitude:obj.geometry.coordinates[1],
                            longitude:obj.geometry.coordinates[0]
                        },
                        label: obj.properties.label,
                    }))
                setAdresse(voies)
                return voies
            })).catch(r => {
                setAdresse([]);
                return []})
    };

    function handleInputChange(d,action){
        if (action.action !== "input-blur" && action.action !== "menu-close" /*&& action.action!=="set-value"*/ ) {
            loadOptions(d)
            setInput(d)
        }
    }

    function handleChange(d){
        onSiteSelected && onSiteSelected(d)
        setLongLat(d.value)
        setInput(d.label)
        setSite(d)
    }



    function handleCopy() {
        window.open(`https://www.google.com/maps/search/${inputValue}`,"_blank")
    }

    return(
        <div className={"mapPointer"}>
            <div className={"search-area"}>

                <Select
                    isClearable={false}
                    placeholder={"Rechercher une adresse"}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onCreateOption={handleCopy}
                    value={site}
                    className={"select-search"}
                    inputValue={inputValue}
                    noOptionsMessage={()=>"Aucun choix"}
                    options={adresse}
                />
                <IconButton className={"button-search"} onClick={handleCopy}>
                    <img src={SEARCHICON}/>
                </IconButton>

                <div className={"search-suggest"}>{inputValue && inputValue}</div>

            </div>

            {site.value.latitude ?

                    <div className={"map"}>
                        <GoogleMap
                            {...mapProps}
                            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}} // set if you need stats etc ...
                            options={{mapTypeId: "satellite",tilt:15,heading:30,gestureHandling:"greedy"}}
                            center={[site.value.latitude, site.value.longitude]}
                            defaultZoom={18}
                            onClick={setLongLat}
                            onChange={handleMapPropsChange}
                        >
                            <Pin key={1}
                                 lat={site.value.latitude}
                                 lng={site.value.longitude}
                                 color={"rgb(0,0,255)"}
                                 pulse={"rgba(255,255,255,0.7)"}
                                 text={""} />
                        </GoogleMap>
                    </div>
                : <img src={MapIcon}/>
            }

                <div className={"tools"}>
                    <div>
                        <img src={InfoToolIcon}/>
                    </div>
                    <div className={"picture"}>
                        <IconButton onClick={()=>{generateStaticImage()}}>
                        <img src={PictureIcon}/>
                        </IconButton>
                    </div>
                    <div className={screenShot?"screenshot front":"screenshot"}>{image && <img alt={"siteImage"} src={image}  />}</div>
                </div>

        </div>

                )
}