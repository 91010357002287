import React, {Fragment, useEffect, useRef, useState} from "react";
import IbatApi from "../utils/IbatApi";
import CustomImage from "../components/util/CustomImage";
import Modal from "react-responsive-modal";
import WorkForce from "../work-force/WorkForce";
import Product from "../product/Product";
import "./layer.css"
import {getIcon} from "../helpers/assetManager";



export default function Layer({points, background}){
    const [state,setState]=useState({})
    const [selectedPoint,setSelectedPoint]=useState(false)
    const canvasRef=useRef();
    const api=new IbatApi()

    function handleBackground(ctx,image){
        var bkGround = new Image();
        bkGround.src = image;
        bkGround.onload = ()=>{
            setState({width:bkGround.width,height:bkGround.height})
            ctx.drawImage(bkGround,0,0);
            points.forEach(p=>draw(ctx,p,bkGround))
        }
    }
    function draw(ctx,p,bkg) {
        const icon=new Image()
        let img=dataIdToImg(p.dataId);
        icon.src = img
        icon.onload=()=> {
            ctx.fillStyle = p.name === "Repair" ? '#00f' : "#ff0000";
            ctx.beginPath()
            ctx.arc(Math.abs(1 - p.y) * bkg.width, Math.abs(p.x) * bkg.height, 5, 0, 2 * Math.PI)
            ctx.fill()
            ctx.drawImage(icon, Math.abs(1 - p.y) * bkg.width - icon.width / 2, Math.abs(p.x) * bkg.height - icon.height / 2)
        }
    }


    function dataIdToImg(id){
        console.log(id)
        return getIcon(id)
    }
    useEffect(()=>{
        const canvas=canvasRef.current
        const context=canvas.getContext('2d')
        api.getFile(background)
            .then(img=>handleBackground(context,img.b64))

    },[])

    function handlePicture(selectedPoint) {
        setSelectedPoint(selectedPoint)
    }

    function getPoints(x,y,area,width,height){
        console.log(points)
        points.map(p=>({...p,x:Math.abs(1-p.y)*height,y:(p.x)*width,picture:p.picture}))
            .filter(p=>Math.abs(p.x-x)<= area && Math.abs(p.y-y)<=area)
            .forEach(p=>handlePicture(p))
    }

    function handlePoint(e) {
        const rect=canvasRef.current.getBoundingClientRect()
        const height=rect.right-rect.left
        const width=rect.bottom-rect.top
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        getPoints(x,y,5,width,height)
    }

    return (
        <Fragment>
            <canvas id={"layer"} width={state.width} height={state.height} onClick={handlePoint} ref={canvasRef}/>
            <Modal open={selectedPoint} onClose={()=>setSelectedPoint(false)}>
                <div className={"layer-modal"}>
                    <CustomImage className={"layer-img"} name={selectedPoint &&selectedPoint.picture.filePath}/>
                    {(selectedPoint.workForces?.length > 0 || selectedPoint.productsAdded?.length>0)&&<div className={"metadata"}>
                        <ul>
                            {selectedPoint.workForces?.map(wf=><li><WorkForce {...wf}/></li>)}
                        </ul>
                        <ul>
                            {selectedPoint.productsAdded?.map(prod=><li><Product {...prod}/></li>)}

                        </ul>
                    </div>}
                </div>
            </Modal>
        </Fragment>
    )

}