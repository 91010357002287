import {useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@material-ui/core";
import CreateIntervention from "../forms/CreateIntervention";
import ValidateFormIcon from "../../images/new-interv-ok.png";
import BonCommande from "../../images/bc-icon.png"
import OpenFormIcon from "../../images/new-interv.png";
import PenIcon from "../../images/pen-edit-icon.png"
import CommentIcon from "../../images/comment.png"
import ContactIcon from "../../images/contact-interaction.png"
import ContactIllustIcon from "../../images/icon-contact.png"
import ReportIcon from "../../images/illust-button-pdf.png"
import CancelInterventionIcon from "../../images/cancel-interv-icon.png"
import "../style/DevisStyle.css"
import ApproIcon from "../../images/supply-icon.png";
import EditSupply from "../forms/EditSupply";
import Modal from "react-responsive-modal";
import InteractionClient from "./InteractionClient";
import EditInterventionTeam from "../forms/EditInterventionTeam";
import EditInterventionDate from "../forms/EditInterventionDate";
import Tooltip from "@material-ui/core/Tooltip";
import {DocumentLoaderList} from "../util/DocumentLoader";
import constants, {text} from "../util/constants";
import {EditText, EditTextarea} from "react-edit-text";
import {useSnackbar} from "notistack";
import ShareSiteOrDevis from "../forms/ShareSiteOrDevis";

export default function Devis() {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {id} = useParams()
  const [state, setState] = useState({})
  const [jobType,setJobType]=useState(false)
  const [clientIdBCommande, setClientIdBCommande] = useState("")
  const [intervention, setIntervention] = useState([])
  const [interventionForm, setInterventionForm] = useState({})
  const [show, setShow] = useState(false)
  const [openTeam, setOpenTeam] = useState(false)
  const [openDate, setOpenDate] = useState(false)
  const [isSavable, setIsSavable] = useState(false)
  const [approModal, setApproModal] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [report, setReport] = useState(false)
  const [interventionMapped, setInterventionMapped] = useState(false)

  const [share, setShare] = useState(false)
  const api = new IbatApi()

  const mySiret = api.getTokenData()?.siret

  useEffect(() => {
    if(!state.id && !jobType){
      getDevis()
      getDevisIntervention()
      api.getJobTypes()
          .then(r=>{
            setJobType(r)
          })
          .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }
    if (!interventionMapped && intervention.length>0 && jobType){
      mapJobType()
    }
  }, [intervention, jobType, id, state])


  function mapJobType(){
    intervention.map(i=> i.jobType = constants[jobType?.filter(jt => jt.id === i.jobType)[0]?.name]??"Couverture")
    setInterventionMapped(true)
  }

  function getDevis() {
    api.getDevis(id).then(devis => {
      setState(devis);
      !devis.site.imageUrl && alert("Une image est requise pour cette commande")
      setClientIdBCommande(devis.clientIdBCommande)
    })
  }

  function getDevisIntervention() {
    setInterventionMapped(false)
    api.getDevisIntervention(id).then(i => {
      setIntervention([...i])
    })
  }

  function handleSave() {
    if (!isSavable) {
      setShow(false)
      return
    }
    let intervention = {
      etat: interventionForm.team?.name && interventionForm.intervention ? constants.SCHEDULED : constants.TOSCHEDULED,
      idSecteur: interventionForm.secteur,
      idTypeIntervention: interventionForm.type,
      teamId: interventionForm.team ? interventionForm.team.id : null,
      dateIntervention: interventionForm.intervention ? new Date(interventionForm.intervention).getTime() : 0,
      jobTypeId: interventionForm.jobTypeId,
      estimateDuration: 3600,
    }
    api.addIntervention(state.id, intervention).then(resp => {
      setInterventionForm({});
      setShow(false)
      setIsSavable(false)
      getDevisIntervention()

    })

  }

  function handleSavable(data) {
    setIsSavable(data.type)
  }

  function handleData(data) {
    setInterventionForm(data)
    handleSavable(data)
  }

  function handleClientIdBCommande(e) {
    setClientIdBCommande(e)
  }

  function saveClientIdBCommande() {
    enqueueSnackbar('Permissions invalides', {variant: "error"})
    /*api.setDevisClientIdBoncommande(id,clientIdBCommande).then(resp=>getDevis())*/
  }

  function handleCommentaire(e) {
    // enqueueSnackbar('Permissions invalides', {variant: "error"})
    api.updateDevisCommentClient(id, state.commentClient).then(console.log)
  }

  function handleCommentaireChange(e) {
    console.log(e)
    if(e.length>constants.MAX_LENGTH){
      return
    }
    setState({...state,commentClient:e})
  }

  function handleContacts(id) {
    setContacts(id)
  }

  function handleReport() {
    setReport(!report)
    console.log(intervention.flatMap(i => i.generatedReport))
  }


  function handleChangeMontant(value) {
    setState({...state, montant: value})
  }

  function saveMontant() {
    enqueueSnackbar('Permissions invalides', {variant: "error"})
    // api.setMontant(id, state.montant)
  }

  function handleChangeAcompte(value) {
    setState({...state, acompte: value})
  }

  function saveAcompte() {
    enqueueSnackbar('Permissions invalides', {variant: "error"})
    // api.setAcompte(id, state.acompte)
  }


  function handleShare(data){
    console.log(data)
    api.shareDevis(id, data.siren, data.siret??"")
        .then(r=> enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant:"success"}))
        .catch(r=> enqueueSnackbar(text.SNACKBAR_ERROR, {variant:"error"}))
  }

  return (
    <div className={"devis-bean"}>
      {!mySiret &&
          <div className={"share"}>
            <Tooltip title={"Partager ce site avec un tiers"}>
              <IconButton onClick={() => setShare(true)}>Share</IconButton>
            </Tooltip>
            <br />
          </div>
      }
      <h1>{text.COMMAND_NUM} {id}</h1>
      <Tooltip title={text.TOOLTIP_CMD_REF}>
        <h2><EditText className={"CmdRef"} onChange={handleClientIdBCommande}
                      value={clientIdBCommande ? clientIdBCommande : constants.NULL} onBlur={saveClientIdBCommande}/>
        </h2>
      </Tooltip>


      <div className={"devis-container"}>
        <div className={"devis-head"}>
          <div className={"tabrow"}>
            <div className={"tabcell"}> {text.AMOUNT}</div>
            <div className={"tabcell"}>
              <EditText className={"tabinput"} type={"number"} value={state.montant} name={"montant"} inline={true} readonly={true}
                        onChange={handleChangeMontant} onBlur={saveMontant}/>
              <span>€</span>
            </div>
            <div className={"tabcell"}>{text.DEPOSIT}</div>
            <div className={"tabcell"}>
              <EditText className={"tabinput"} type={"number"} value={state.acompte} name={"acompte"} inline={true} readonly={true}
                        onChange={handleChangeAcompte} onBlur={saveAcompte}/>
              <span>€</span>
            </div>
          </div>
          <div className={"tabrow"}>
            <div className={"tabcell"}>{text.CLIENT_2}</div>
            {state.client && <a className={"tabcell"} href={`/client/${state.client.id}`}> {state.client.name}</a>}

            <div className={"tabcell"}>{text.SITE_2}</div>
            {state.site && <a className={"tabcell"} href={`/site/${state.site.id}`}> {state.site.name}</a>}
          </div>
          <div className={"tabrow"}>
            <div className={"tabcell"}>{text.RECEPTION_DATE}</div>
            <div className={"tabcell"}>{state.dateReception && new Date(state.dateReception).toLocaleDateString()}</div>
          </div>
        </div>
      </div>

      <div className={"devis-comments"}>
        <h3><img src={CommentIcon}/>{text.NOTES_AND_COMMENTS}</h3>
        <EditTextarea className={"textedit"} value={state?.commentClient??""} onChange={handleCommentaireChange} rows={6}  onBlur={handleCommentaire}> </EditTextarea>
      </div>

      <div className={"devis-intervention"}>
        <h2>{text.INTERVENTION_LINKED}</h2>
        <table>
          <thead>
          <tr>
            <th>{text.INTERVENTION_NUMBER}</th>
            <th>{text.STATUS}</th>
            <th>{text.FILE_TYPE}</th>
            <th>{text.COMPANY_NAME}</th>
            <th>{text.JOBTYPE}</th>
            <th>{text.CONTACTS}</th>
            <th>{text.INTERVENTION_START}</th>
            <th>{text.INTERVENTION_END}</th>
            <th>{text.OBJECT}</th>
          </tr>
          </thead>
          <tbody>
          {intervention.length > 0 && intervention.map(i => <tr>
            <td><a href={`/intervention/${i.id}`}> {i.id}</a></td>
            <td>{text.STATUS_TRADUCTION[i.status]}</td>
            <td>{i.type.name}</td>
            <td onClick={()=>{
              if (state?.company?.id > 0){
                window.open(`/company/${state.company.id}`)
              }
            }}><a>{state?.company?.name}</a></td>
            <td>{i.jobType}</td>
            <td>
              <Tooltip title={text.TOOLTIP_LIST}>
                <IconButton onClick={() => handleContacts(i.id)}><img src={ContactIcon}/></IconButton>
              </Tooltip>
            </td>
            <td
              onClick={() => setOpenDate(i.id)}>{i.dateIntervention > 0 ? new Date(i.dateIntervention).toLocaleDateString() : "-"}</td>
            <td>{i.endIntervention > 0 ? new Date(i.endIntervention).toLocaleDateString() : "-"}</td>
            <Modal open={openTeam === i.id} onClose={() => setOpenTeam(false)}>
              <EditInterventionTeam id={openTeam} update={() => {
                getDevisIntervention();
                setOpenTeam(false)
              }}/>
            </Modal>
            <Modal open={openDate === i.id} onClose={() => setOpenDate(false)}>
              <EditInterventionDate id={openDate} update={() => {
                getDevisIntervention();
                setOpenDate(false)
              }}/>
            </Modal>
            <td>{i.object ? i.object : text.TO_DEFINE}</td>
          </tr>)}
          </tbody>
          <Modal open={contacts} onClose={() => setContacts(false)}>
            <InteractionClient id={contacts} devis={contacts === id}/>
          </Modal>
        </table>
      </div>

      <div className={"devis-sideBar"}>
        <IconButton disabled><img src={BonCommande}/></IconButton>
        <Tooltip title={text.TOOLTIP_ADD_INTERV + " " + id}>
          <IconButton onClick={() => {
            setShow(true)
          }}>
            <img src={OpenFormIcon}/>
          </IconButton>
        </Tooltip>
        <Tooltip title={text.TOOLTIP_CLIENT_CHAT}>
          <IconButton onClick={() => handleContacts(id)}><img src={ContactIllustIcon}/></IconButton>
        </Tooltip>
        {/*<Tooltip title={text.TOOLTIP_SUPPLY}>
                    <IconButton onClick={()=>setApproModal(!approModal)}><img src={ApproIcon}/></IconButton>
                </Tooltip>*/}
        <Tooltip title={text.TOOLTIP_SAVED_REPORT_LIST}>
          <IconButton onClick={() => handleReport(id)}><img src={ReportIcon}/></IconButton>
        </Tooltip>
        <Modal open={report} onClose={() => setReport(false)}>
          {intervention.flatMap(i => i.generatedReport).length > 0 ?
            <DocumentLoaderList name={intervention.flatMap(i => i.generatedReport)}/> : text.NO_REPORT_SAVED}
        </Modal>
        <IconButton disabled>
          <img src={CancelInterventionIcon}/>
        </IconButton>

        <Modal open={show} onClose={() => setShow(false)}>
          <div className={"commande-form"}>
            <div className={"commande-head"}>
              <h3>{text.CREATE_INTERV}</h3>
            </div>

            <div className={"commande-data"}>
              <CreateIntervention onValid={handleData} classic />
            </div>
            <IconButton className={`${isSavable ? "validation" : "cancel"}-button`} onClick={() => {
              handleSave();
              setShow(false)
            }}>
              {isSavable ? text.CREATE : text.CANCEL}
              {/*<img src={isSavable?ValidateFormIcon:OpenFormIcon}/>*/}
            </IconButton>
          </div>
        </Modal>

      </div>

      <Modal open={share} onClose={()=> setShare(false)}>
        <ShareSiteOrDevis siteOrDevis={"site"} share={handleShare}/>
      </Modal>

    </div>
  )


}
