import React, {useEffect, useState} from "react";
import UserManager, {UserEditorMod} from "../lists/UserManager";
import "../style/Parameter.css"
import constants, {text} from "../util/constants";
import IbatApi from "../../utils/IbatApi";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Statistiques} from "../../statistiques/Statistiques";
import usersImg from "../../images/users-config.png";
import statImg from "../../images/stats-icon.png";
import miscImg from "../../images/misc-icon.png";
import ebatLogo from "../../images/ebat-logo.png";
// import CompanyEditForm from "../forms/CompanyEditForm";



export default function Parameter(props){
    const api=new IbatApi()
    // const[company, setCompany] = useState()
    // const[companyId, setCompanyId] = useState(api.getTokenData().companyId)
    const [expanded, setExpanded] = useState(false);
    const[userToMod, setUserToMod] = useState(false)
    const[userList, setUserList] = useState(false)
    const handleUserToMod = (data) => {
        setUserToMod(data)
    }

    useEffect(() => {
        updateUserList()
        setUserToMod(userToMod)
    }, [userToMod])
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    /*function handleCompany(c) {
        setCompany(c)
    }*/

    function updateUserList(){
        api.getAllUser().then(data=> {
            setUserList(data)
        })
    }


    return(
        <div>
            <div className={"accordionGroup"}>
                <div className={"paramTitle"}><h1>Paramètres</h1></div>
                <Accordion id={"accordion"} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel-content"
                        id="panel-header">
                        <img src={usersImg}/>
                        <Typography sx={{width: '33%', flexShrink: 0}}><h2>Gestion des Utilisateurs</h2>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails id={"accordionDetails"}>
                        <Typography>
                            <UserManager handleClick={handleUserToMod} allUser={userList} updateUserList={updateUserList}/>
                            <UserEditorMod user={userToMod} updateUserList={updateUserList}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*<Accordion id={"accordion"} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2bh-content"
                        id="panel-header">
                        <img src={miscImg}/>
                        <Typography sx={{width: '33%', flexShrink: 0}}><h2>{text.OTHER_PARAM}</h2></Typography>
                    </AccordionSummary>
                    <AccordionDetails id={"accordionDetails"}>
                        <Typography>
                            <div className={"miscParam"}>
                                {company && <CompanyEditForm company={company}/>}
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion id={"accordion"} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel3bh-content"
                        id="panel-header"><img src={statImg}/>
                        <Typography sx={{width: '33%', flexShrink: 0}}><h2>Statistiques de l'Entreprise</h2>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails id={"accordionDetails"}>
                        <Typography>
                            <Statistiques id={companyId} handleCompany={handleCompany}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>*/}
            </div>

            <center><img src={ebatLogo}/><br />Merci d'utiliser eBat !</center>

        </div>
    )
}
