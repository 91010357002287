import {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import '../style/NewServiceProviderSelectStyle.css'
import {IconButton} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";
import CreateSite from "./CreateSite";

export default function SearchSiteForClient({onCancel}){

    let api = new IbatApi();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selected, setSelected] = useState()
    const [site, setSite] = useState()
    const [mode, setMode] = useState("search")
    const [filter, setFilter] = useState({cp:"", address:"", ville: ""})


    useEffect(()=>{

        loadOptions()
    }, [filter])

    let loadOptions=()=>{
        return api.getAllSiteFilterd(filter).then(resp => {
            setSite(resp.content)
        })
    }

    function handleInputChange(d){
        // setName(d)
        setFilter( {...filter, [d.target.id]: d.target.value})
        // loadOptions()
    }
    function getSiteRow(c){
        return (
            <tr className={selected === c.id ? "selected": "result-row"} onClick={()=>setSelected(c.id)}>
                <td>{c.id}</td>
                <td>{c.name}</td>
                <td>{c.address}</td>
                <td>{c.cp}</td>
                <td>{c.ville}</td>
            </tr>
        )
    }

    function createSiteClient(id, siret){
        api.updateClientSite(selected ?? id, {id:null, internal_reference: null, internal_secteur: null, manager: null, siren: null, siret: siret ?? null, site: null, archived: false, sharedBy: null})
            .then(r => {
                window.open(`/site/${selected??id}`, "_blank")
                enqueueSnackbar(text.SNACKBAR_SUCCESS,{variant:"success"})
                onCancel()

            })
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }
    function createSite(d, siret){
        createSiteClient(d, siret)}

    function searchMode(){
        return (
            <div className={"new-service-provider-select"}>
                <h1>Rechercher un site existant</h1>
                <div className={"filters"}>
                    <input type={"number"} onChange={handleInputChange} placeholder={"Code postal"} id={"cp"}/>
                    <input type={"text"} disabled={!filter.cp} onChange={handleInputChange} placeholder={"Ville"} id={"ville"}/>
                    <input type={"text"} disabled={!filter.ville} onChange={handleInputChange} placeholder={"Adresse"} id={"address"}/>
                </div>

                {site &&
                    <table className={"result"}>
                        <tr>
                            <td>id</td>
                            <td>Nom</td>
                            <td>Adresse</td>
                            <td>Code Postal</td>
                            <td>Ville</td>
                        </tr>
                        {site?.map(c=>getSiteRow(c))}
                    </table>}


                <div className={"inline"}>
                    <IconButton className={"more-button validation-button"} onClick={()=>{
                        setMode("create")
                        setSelected()
                    }}>{"Je ne trouve pas mon site."}</IconButton>
                    {selected &&
                        <IconButton className={"validation-button"} onClick={createSiteClient}>{"Ajouter ce site à ma liste."}</IconButton>
                    }
                </div>
            </div>
        )
    }

    if (mode === "search"){
        return searchMode()
    }
    else if (mode === "create"){
        return <CreateSite onSave={createSite} name={""} onCancel={onCancel}/>
    }
}