import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import StepContainer from "../step/StepContainer";
import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import constants, {text} from "../components/util/constants";
import {useSnackbar} from "notistack";
import IbatApi from "../utils/IbatApi";
import {v4} from "uuid";
import {timeToDate} from "../helpers/timeToDate";

const Calendar=forwardRef(({begin,end,team,onHover,maps,map},ref)=>{

    const {enqueueSnackbar,closeSnackbar}=useSnackbar()
    const [plannedIntervention,setPlannedIntervention]=useState([])
    const [events,setEvents]=useState([])
    const [removed,setRemoved]=useState([])
    const api=new IbatApi()

    useImperativeHandle(ref,()=>({
         addEvent(event){
             event.id=event.id || v4()
             console.log(event.id)
             setEvents([...events,event])
        },

        save(){
             handleSave()
        },

        getEvents(team,begin,end){
             getCalendar(team,begin,end)
        },

        travelTime(){
             handleTravelTime()
        }
    }))


    function handleTravelTime() {
        updateTravelTime(events.sort((e1,e2)=>e1.start-e2.start).map(e=>e.intervention))

            .then(()=>enqueueSnackbar(text.SNACKBAR_SUCCESS, {variant: "success"}))
            .catch(e=>{
                console.log(e)
                enqueueSnackbar(text.TRAVEL_TIME_ERROR, {variant: "error"})
            })
    }

    function updateTravelTime(copy){
        let find = copy.find(i=>!i.devis.site.latitude || !i.devis.site.longitude);
        //todo attention ajouter dans les traduction file
        if (find){
            alert(`l'intervention sur le site ${find.devis.site.name} pause probleme`)
            return
        }
        let origins=copy.slice(0,copy.length-1)
        let destinations=copy.slice(1)
        console.log(origins.map(el=>({lat:el.devis.site.latitude,lng:el.devis.site.longitude})))
        console.log(destinations.map(el=>({lat:el.devis.site.latitude,lng:el.devis.site.longitude})))
        return getTimeBetween(origins,destinations)
            .then(elems=>{
                return elems.map(elem=>elem.duration.value*constants.S1)
            })
            .then( times=>times.forEach((traveltimeCorrection,originIndex)=>{
                    let destinationindex=originIndex+1
                    let boolean = copy[destinationindex].dateIntervention-copy[originIndex].dateIntervention-copy[originIndex].estimateDuration*constants.S1<traveltimeCorrection;
                    if(boolean){
                        copy[destinationindex].dateIntervention=copy[originIndex].dateIntervention+copy[originIndex].estimateDuration*constants.S1+traveltimeCorrection
                    }
                })
            )


    }

    function getTimeBetween(originPoints,destinationPoints){
        return new Promise(resolve=> {
            const origin = originPoints.map(originPoint => ({
                lng: originPoint.devis.site.longitude,
                lat: originPoint.devis.site.latitude
            }))
            const destination = destinationPoints.map(destinationPoint => ({
                lng: destinationPoint.devis.site.longitude,
                lat: destinationPoint.devis.site.latitude
            }))
            maps.getDistanceMatrix(
                {
                    origins: [...origin],
                    destinations: [...destination],
                    travelMode: map.TravelMode.DRIVING,
                    unitSystem: map.UnitSystem.METRIC,
                }, (response, status) => {
                    if (status !== 'OK') {
                        console.log(status)
                        resolve(0)
                    }
                    console.log("resp",response)
                    resolve(response.rows.map((element,index)=>element.elements[index]))
                })
        })
    }


    function handleSave(){
        events.forEach(evt=>evt.intervention.team=team)

        function getTextEvent(event) {
            return `${event.intervention.devis.site.name} ${timeToDate(event.start)}`
        }

        api.deleteEvent(removed)
            .then(setRemoved([]))
        api.createUpdateEvent(events)
            .then(res=>res.map(event=>enqueueSnackbar(getTextEvent(event),{variant: "error"})))
            .then(e=>enqueueSnackbar(text.SNACKBAR_SAVED,{variant: "success"}))

    }


    function getCalendar(team,begin,end){
        setRemoved([])
        api.getPlanningEvent(team,begin,end+constants.H24).then(res=>setEvents(res))

    }

    function gridData(){
        if(!begin || !end){
            return []
        }
        const daynumber= Math.abs(begin-end)/(constants.H24)
        let a=new Array(daynumber+1).fill(1).map((elem,index)=>begin+(index*constants.H24))
        return a

    }

    function getDaySpan(event){
        let intvdate=new Date(event.start)
        let startTime=intvdate.getHours()*constants.H1+intvdate.getMinutes()*constants.M1;
        let dayspan=startTime+(event.endEvent-event.start)
        return Math.abs(Math.floor(-dayspan/constants.H24))-1
    }

    function splitIntv(listEvent) {
        let list=[]
        for (let event of listEvent){
            let daySpan=getDaySpan(event)
            let res=[event]
            if(daySpan!==0){
                let eventdate=new Date(event.start)
                let startTime=eventdate.getHours()*constants.H1+eventdate.getMinutes()*constants.M1;
                let firstChild={...event,endEvent:(event.start+(constants.H24-startTime))}
                let midChilds=Array.from({length:daySpan-1},((v, k) => k+1))
                    .map(k=>(
                        {...event,
                            start:event.start-startTime+k*constants.H24,
                            endEvent:event.start-startTime+(k+1)*constants.H24
                        }
                    ))
                let lstChild={...event
                    ,start:event.start-startTime+daySpan*constants.H24,
                    endEvent:event.start-startTime+daySpan*constants.H24+((event.endEvent-event.start)+startTime)%constants.H24}
                res=[firstChild,...midChilds,lstChild]
            }
            list.push(res)
        }
        return list.flat(1)
    }

    function handleTimeChange(idIntv,newTime){
        let updatedList =[...plannedIntervention]
        let i=updatedList.find(intv=>intv.id===idIntv)
        i.estimateDuration=newTime
        setPlannedIntervention(updatedList)
    }

    function updateIntervention(eventId, date) {
        let updatedList =[...events]
        let i=updatedList.find(evt=>evt.id===eventId)
        let duration=i.endEvent-i.start
        console.log(i,i.endEvent-i.start)
        i.start=date
        i.endEvent=date+duration
        console.log(i,i.endEvent-i.start)
        setEvents(updatedList)
    }

    function remove(event){
        let eventToRemoveId=events.findIndex(evnt=>evnt.id===event.id);
        let eventToRemove=events.splice(eventToRemoveId,1)
        console.log(eventToRemove,events)
        setRemoved([...removed,...eventToRemove])
        // setEvents( events.filter(evnt=>evnt.id!==eventToRemove.id))
    }

    return (
        <Fragment>
            <DndProvider  backend={HTML5Backend} >
                <div className={"container-list"}>
                    <div className={"container-table"}>
                        <table>
                            <thead>
                            <tr>
                                {gridData().map(date=>
                                    <td className={"step-date"}>
                                        {
                                            new Date(parseInt(date))
                                                .toLocaleDateString(undefined,{ weekday: 'long', month: 'numeric', day: 'numeric' ,})
                                        }
                                    </td>)
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {gridData().map((date,index)=>
                                <StepContainer key={date}
                                               isScrollActive={index===0}
                                               beginDate={date}
                                               onTimeChange={handleTimeChange}
                                               eventList={splitIntv(events)}
                                               onRemove={remove}
                                               onHover={id=>onHover(id)}
                                               moveStep={updateIntervention}/>
                            )}
                            </tbody>

                        </table>
                    </div>

                </div>

            </DndProvider>

        </Fragment>
        )
})

export default Calendar;