import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {Modal} from "react-responsive-modal";
import ClientForm from "./ClientForm";
import Select from "react-select";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import NewServiceProviderSelect from "./NewServiceProviderSelect";

export default function SearchServiceProvider({onSave, jobType}) {
    const [serviceProviderList, setServiceProviderList] = useState([])
    const [serviceProvider, setServiceProvider] = useState()
    const [popup, setPopup] = useState(false)
    const [name, setName] = useState()
    let api = new IbatApi();

    let loadOptions=(inptv)=>{
        return api.getCompanies({name:inptv, jobTypeId:jobType}).then(resp => {
            let map = resp?.content?.map(s => ({label: `${s.name} ${s.ville} (${s.siren})`, name: s.name, id: s.id,value:s.id}))
            setServiceProviderList(map)
            return map
        })
    }

    function handleInputChange(d){
        // setName(d)
        loadOptions(d)
    }

    function handleCreate(d){
        setName(d)
        setPopup(true)
    }

    function handleNewServiceProvider(s){
        setServiceProvider({label: `${s.name} ${s.ville} (${s.siren})`, name: s.name, id: s.id,value:s.id})
        // onSave({id:data.id,name:data.name})
        onSave({id:s.id,name:`${s.name} ${s.ville} (${s.siren})`})
        setPopup(false)
    }

    function handleCancel(){
        setServiceProviderList(null)
        setPopup(false)

    }

    function handleChange(d){
        onSave({id:d?.value,name:d?.label})
        setServiceProvider({value:d?.value,label:d?.label})
    }

    return (
        <div className={"createSite"}>
            <CreatableSelect
                classNamePrefix={"search"}
                placeholder={"Rechercher un prestataire"}
                isClearable
                onChange={handleChange}
                onInputChange={handleInputChange}
                isLoading={popup}
                onCreateOption={handleCreate}
                value={serviceProvider}
                formatCreateLabel={()=>`Recherche Avancée`}
                noOptionsMessage={()=>text.NO_ENTRY_SELECTED}
                options={serviceProviderList}
            />
            <Modal open={popup} onClose={() => setPopup(false)}>
                <NewServiceProviderSelect name={name} onCancel={handleCancel} jobType={jobType} onSave={handleNewServiceProvider}/>
            </Modal>

        </div>
    )
}
