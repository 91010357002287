import GoogleMap from "google-map-react";
import constants, {text} from "../components/util/constants";
import {getPinColor, getShape, isModifiable} from "../helpers/interventionManager";
import Pin from "../components/util/Pin";
import Modal from "react-responsive-modal";
import InteractionClient from "../components/bean/InteractionClient";
import React, {useEffect, useImperativeHandle, useState} from "react";
import EventCreator from "../event-creator/EventCreator";
import IbatApi from "../utils/IbatApi";
import "./PlanningMap.css"
import Select from "react-select";

const {forwardRef} = require("react");
const PlanningMap=forwardRef(({onApiLoaded,hovered,handleAddPin},ref)=> {

    const api=new IbatApi()
    const [openInteractionClient,setOpenInteractionClient]=useState()
    const [hoveredId,setHoveredId]=useState(hovered)
    const [pinList,setPinList]=useState([])
    const [openEventForm,setOpenEventForm]=useState()

    useEffect(()=>{setHoveredId(hovered)},[hovered])

    useImperativeHandle(ref,()=>({
        getinterventions(teamId,begin,end){
            api.getInterventionToPlann(teamId,begin,end+constants.H24).then(res=>setPinList(res))
        }
    }))


    return ( <div className={"planning-map"}>
        <div className={"plan-search"}>
            <Select
                classNamePrefix={"search"}
                placeholder={text.SITE_SEARCH}
                options={pinList.map(i=>({value:i.id,label:i.devis.site.name+" "+i.devis.site.adresse+" "+i.devis.site.cp+" "+i.devis.site.ville}))}
                onChange={e=>setHoveredId(e?e.value:e)}
                isClearable
            />

        </div>
            <div  className={"map"}>
                <GoogleMap
                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                    defaultCenter={constants.MAPCENTER}
                    defaultZoom={6}
                    options={{mapId:"b989d61f03f5b1dc",gestureHandling:"greedy",clickableIcons:false}}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps })=>onApiLoaded(map,maps)}
                >
                    {pinList.filter(i=>isModifiable(i) || i.status===constants.STATUS_PROCESSING)
                        .map((p) => <Pin key={p.id}
                                         lat={p.devis.site.latitude}
                                         lng={p.devis.site.longitude}
                                         text={p.id}
                                         data={p}
                                         hovered={hoveredId===p.id}
                                         color={getPinColor(p.type.id)}
                                         shape={getShape(p.status)}
                                         onClick={data=>setOpenEventForm(data)}>
                            <div className={"planning-card"}>
                                <div>
                                    <div style={{backgroundColor:getPinColor(p.type.id)}} className={"intervention-type"}>
                                        {p.type.name} [{text.STATUS_TRADUCTION[p.status]}]
                                    </div>

                                    <a href={`/interventions/${p.id}`} target={"_blank"}>
                                        {p.devis.site.name} {p.devis.site.adresse} {p.devis.site.cp} {p.devis.site.ville}
                                    </a>

                                    <ul onClick={()=>setOpenInteractionClient(p.id)}>
                                        {p.devis.site?.contactPhone?.map((ph,id)=><li key={id}>{ph}</li>)}

                                    </ul>
                                    <Modal open={openInteractionClient===p.id} onClose={()=>setOpenInteractionClient(false)}>
                                        <InteractionClient id={p.id}/>
                                    </Modal>
                                </div>
                                <div className={"date-intervention"}>
                                    {p.dateIntervention>0?`${new Date(p.dateIntervention).toLocaleDateString()} ${new Date(p.dateIntervention).toLocaleTimeString()}`:text.UNDEFINED_DATE}
                                </div>
                            </div>
                        </Pin>)}
                </GoogleMap>
            <Modal open={openEventForm} onClose={()=>setOpenEventForm(false)}>
                <EventCreator intervention={{...openEventForm}} onSave={handleAddPin}/>
            </Modal>
        </div>
    </div>)
})
export default PlanningMap;
