import IbatApi from "../../utils/IbatApi";
import React from "react";
import { Redirect, Route } from 'react-router-dom'

export default function PrivateRoute({component, properties,...rest }){

    let api = new IbatApi();
    const isLoggedIn = api.getToken()!==""

    return (
        <Route {...rest}
            render={props =>

                isLoggedIn ? (React.createElement(component, {...properties})) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}