import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import constants, {text} from "../components/util/constants";
import IbatApi from "../utils/IbatApi";
import "./EventCreator.css";
import {timeToDate} from "../helpers/timeToDate";

export default function EventCreator({intervention,onSave}){


    let [state,setState] = useState({time:timeToDate(intervention.estimateDuration*constants.S1),duration:intervention.estimateDuration*constants.S1});
    const api=new IbatApi()

    function handleDate(e) {
        let value = new Date(e.target.value).getTime();
        let name= e.target.name;
        setState({...state,[name]:value})
    }

    function handleSave() {
        console.log({...state,intervention:intervention,endEvent:state.start+state.duration})
        onSave({...state,intervention:intervention,endEvent:state.start+state.duration})
        // api.setInterventionTeam(intervention.id,intervention.team.id).then()
        // api.createUpdateEvent({...state,intervention:intervention}).then(event=>console.log(event))

    }

    function handleDuration(e) {
        let time = e.target.value;
        let [heure,minute]=time.split(':')
        let duration= heure*constants.H1+minute*constants.M1
        setState({...state,time:time,duration:duration})
    }

    return(

        <div className={"eventCreator"}>
            <h2>{text.ADD_EVENT}</h2>
            <input type={"datetime-local"} onChange={handleDate} name={"start"}/>
            <input type={"time"} onChange={handleDuration} value={state.time} name={"time"}/>
            <IconButton className={"validation-button"} onClick={handleSave}>{text.CREATE}</IconButton>
        </div>
    )

}