import React, {useState, useEffect} from "react"
import IbatApi from "../../utils/IbatApi";

import IconRepIn from "../../images/icon-rep-in.png"
import IconCall from "../../images/icon-call.png"
import IconSms from "../../images/icon-sms.png"
import IconMail from "../../images/icon-mail.png"
import IconRep from "../../images/icon-rep.png"
import IconCallIn from "../../images/icon-call-in.png"
import IconSmsIn from "../../images/icon-sms-in.png"
import IconMailIn from "../../images/icon-mail-in.png"
import MinIconCallIn from "../../images/min-call-in.png"
import MinIconSmsIn from "../../images/min-sms-in.png"
import MinIconMailIn from "../../images/min-mail-in.png"
import MinIconRepIn from "../../images/min-rep-in.png"
import MinIconCall from "../../images/min-call.png"
import MinIconSms from "../../images/min-sms.png"
import MinIconMail from "../../images/min-mail.png"
import MinIconRep from "../../images/min-rep.png"
import ContactIcon from "../../images/contact-illus.png"
import "../style/InteractionFormStyle.css"
import TeamInteraction from "./TeamInteraction";
import constants from "../util/constants";
import {text} from "../util/constants";
import {useSnackbar} from "notistack";

export default function InteractionClient({id, devis}) {
  const [client, setClient] = useState({content: ""})
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  useEffect(() => {
    api.getClientInteraction(id, devis).then(data => setClient(client => ({...client, interactions: data})))
  }, [])
  const api = new IbatApi()
  const dateOption = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric",}

  function handleInteraction(e) {

    enqueueSnackbar("Opération impossible", {variant: "error"})
    /*console.log(e.target)
    let time = new Date()
    const data = {
        type: e.target.name,
        dateEmission: time.getTime(),
        content: "-"+api.getTokenData().username+" : "+client.content
    }
    api.addClientInteraction(id, data,devis).then(data1 => {
        setClient(client => ({...client, content: "", interactions: data1.devis?data1.devis.interactionClients:data1.interactionClients}))
    })*/
  }

  function handleContent(e) {
    setClient(client => ({...client, content: e.target.value}))
  }

  function getIcon(type) {
    if (type === "sms-in") return <img alt={""} style={{marginRight: 3}} src={MinIconSmsIn}/>
    if (type === "mail-in") return <img alt={""} style={{marginRight: 3}} src={MinIconMailIn}/>
    if (type === "call-in") return <img alt={""} style={{marginRight: 3}} src={MinIconCallIn}/>
    if (type === "sms-out") return <img alt={""} style={{marginRight: 3}} src={MinIconSms}/>
    if (type === "mail-out") return <img alt={""} style={{marginRight: 3}} src={MinIconMail}/>
    if (type === "call-out") return <img alt={""} style={{marginRight: 3}} src={MinIconCall}/>
    if (type === "voice-out") return <img alt={""} style={{marginRight: 3}} src={MinIconRep}/>
    return <img alt={""} style={{marginRight: 3}} name={""} src={MinIconRepIn}/>
  }


  /*if(!api.hasRole(constants.PERMISSION_AM)){
      return <h3>{text.NO_RIGHT}</h3>
  }*/
  return (
    <div className={"interaction"}>
      <h2><img alt={""} src={ContactIcon}/> {text.H2_CONTACT_REPORT}</h2>
      <div className={"entree"}>
        {client.interactions &&
        <div className={"outputs"}>
          {client.interactions
            .map((it, idex) => <div key={idex}>
              {getIcon(it.type)} {new Date(it.dateEmission).toLocaleDateString(undefined, dateOption)} {it.content}  </div>)}
        </div>
        }
        <label htmlFor={"content"}>{text.ADD_MESSAGE}</label>
        <textarea name={"content"} id={"content"} style={{background: "#AAA"}} value={client.content}
                  onChange={handleContent} disabled={true}/>
        <div className={"buttons"}>
          <button name={"sms"} onClick={handleInteraction}><img alt={"sms-in"} name={"sms-in"} src={IconSmsIn}/>
          </button>
          <button name={"mail"} onClick={handleInteraction}><img alt={"mail-in"} name={"mail-in"} src={IconMailIn}/>
          </button>
          <button name={"call"} onClick={handleInteraction}><img alt={"call-in"} name={"call-in"} src={IconCallIn}/>
          </button>
          <button name={"voice"} onClick={handleInteraction}><img alt={"voice-in"} name={"voice-in"} src={IconRepIn}/>
          </button>
          <button name={"sms"} onClick={handleInteraction}><img alt={"sms-out"} name={"sms-out"} src={IconSms}/>
          </button>
          <button name={"mail"} onClick={handleInteraction}><img alt={"mail-out"} name={"mail-out"} src={IconMail}/>
          </button>
          <button name={"call"} onClick={handleInteraction}><img alt={"call-out"} name={"call-out"} src={IconCall}/>
          </button>
          <button name={"voice"} onClick={handleInteraction}><img alt={"voice-out"} name={"voice-out"} src={IconRep}/>
          </button>

        </div>
      </div>
    </div>
  )
}
