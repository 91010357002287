import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import SearchSite from "./SearchSite";
import { text } from "../util/constants";
import {isClassic, isSpecialIntervention} from "../../helpers/interventionManager";
import JobTypeSelector from "./JobTypeSelector";

export default function CreateInterventionForm({onValid,children,onSiteChange,classic, site, mySiret, requestMode}){
    const default_state={}
    let api = new IbatApi();
    const [state, setState] = useState(default_state)
    const [types, setTypes] = useState()
    const [jobTypeList, setJobTypeList] = useState()

    useEffect(() => {
        api.getTypes().then(types => {
            setTypes(mySiret ? types.filter((type)=> type.id === 2 || type.id === 3 || type.id === 4 ).map((type)=>{
                if (type.name === "Chantier"){
                    type.name = "Travaux"
                }
                return type
            }) : types.map((type)=>{
                if (type.name === "Chantier"){
                    type.name = "Travaux"
                }
                return type
            }))
        })
        if (!jobTypeList){
            api.getJobTypes().then(jt => {
                setJobTypeList(jt.map(jt => {
                        return {
                            label: jt.name,
                            value: jt.id
                        }
                    }
                ))
            })
        }
    }, [])

    function handleType(e) {
        setState({...state, type: e.target.value})
        onValid({...state, type: e.target.value})
    }

    function handleJobType(e) {
        setState({...state, jobTypeId: e.target.value})
        onValid({...state, jobTypeId: e.target.value})
    }


    return (
        <Fragment>
            <div title={text.FILE_TYPE}>
                <select required onChange={handleType} className={"cmdCreatInput"}>
                    <option defaultValue="" disabled selected>{text.FILE_TYPE}</option>
                    {types && types.sort((t1,t2)=>t1.name>t2.name?1:-1).filter(t=>!classic || isClassic(t.id))
                        .map(t => <option value={t.id}>{t.name}</option>)}
                </select>
            </div>

            {/*{jobTypeList && <div title={"Corps de métier"}>
                <select required onChange={handleJobType} className={"cmdCreatInput"}>
                    <option defaultValue="" disabled selected>Corps de métier</option>
                    {jobTypeList.sort((a, b) => a?.label > b?.label ? 1 : -1)?.map(ut => <option
                        value={ut.value}>{ut.label}</option>)}
                </select>
            </div>}*/}
            {!requestMode && <JobTypeSelector onChange={handleJobType}/>}

            {!isSpecialIntervention(state.type) &&  children}

            {!classic && onSiteChange &&
            <div title={text.SITE} className={"cell commande-search"}>
                <SearchSite className={"cmdCreatInput"} onSave={onSiteChange} preSelectionnedSite={{...site}}/>
            </div>}

        </Fragment>
    )
}