import React, {useState} from "react";
import IbatApi from "../../utils/IbatApi";
import BillIcon from "../../images/new-devis.png";
import {IconButton} from "@material-ui/core";
import constants from "../util/constants";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";

export default function EditBillForm({idDev,update}){
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [bill,setBill] =useState("");

    let api = new IbatApi();
    function handleTime(e) {
        setBill(e.target.value);
    }

    function handleSave() {
        api.setBill(idDev,bill).then(c=>update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }



    if(!api.hasRole(constants.PERMISSION_BILL)){
        return <h3>{text.NO_RIGHT_PROMPT}</h3>
    }
    return(<div className={"editIntervention"}>
            <div className={"img-container"}>
                <img src={BillIcon}/>
            </div>
            <div className={"container"}>
                <h2>{text.BILL_NUMBER}</h2>
                <input type={"text"} placeholder={text.BILL_NUMBER} value={bill} onChange={handleTime}/>
                <IconButton className={"validation-button"} onClick={handleSave}>Enregistrer</IconButton>
            </div>

        </div>

    )
}