import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";

export default function CustomImage({className,width,height,name,layerName,onClick,onRightClick}){
    const api=new IbatApi()
    const [image,setImage]=useState()
    const [canvas,setCanvas]=useState()


    useEffect(()=>{
        if (!layerName){
            return
        }
        layerSuperposition().then(b64=>setCanvas(b64))

    },[layerName])

    useEffect(()=>{
        if (name){
            api.getFile(name).then(img=>setImage(img.url))
        }
    },[name])

    function handleRightClick(e) {
        e.preventDefault()
        onRightClick&&onRightClick()
    }

    function loadImg(img, ctx,cnvas) {
        return new Promise ( resolve=>{
            let bkGround = new Image();
            api.getFile(img).then(file=>bkGround.src=file.url);
            bkGround.onload = ()=>{
                cnvas.height = bkGround.height
                cnvas.width = bkGround.width
                ctx.drawImage(bkGround, 0, 0);
                resolve(ctx)
            }
        })
    }

    function draw(ctx,img,cnvas) {
        return new Promise(resolve => {
            const icon = new Image()
            api.getFile(img).then(file => icon.src = file.url);
            icon.onload = () => {
                // cnvas.height = icon.height
                // cnvas.width = icon.width
                ctx.drawImage(icon, 0 ,0,cnvas.width,cnvas.height)
                resolve(ctx)
            }
        })
    }

    function layerSuperposition(){
        let canvs = document.createElement('canvas');
        const ctx = canvs.getContext('2d');
        return loadImg(name,ctx,canvs)
            .then(ctx=> draw(ctx,layerName,canvs))
            .then(()=>canvs.toDataURL())
    }

    return(
            <img alt={name} className={className} onContextMenu={handleRightClick} onClick={onClick} width={width} height={height} src={layerName?canvas:image}/>
    )
}

