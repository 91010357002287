import IbatApi from "../../utils/IbatApi";
import Modal from "react-responsive-modal";
import {Fragment, useEffect, useLayoutEffect, useState} from "react";
import CustomImage from "./CustomImage";
import PDFIcon from "../../images/pdf-icon.png"
import  "../style/DocumentLodaerStyle.css"
import Loading from "./Loading";
import {Tooltip} from "@material-ui/core";
import { text } from "../util/constants";

export default function DocumentLoader({name, type} ){
    const api=new IbatApi()
    const [show,setShow]=useState(false)
    const [doc,setDocument]=useState()

    useEffect(()=> {
        console.log(doc)
    }, [])

    function openModal() {
        if (typeof(name) === "string"){
            api.getFile(name).then(resp => {
                setDocument(resp.url)
            })
        }
        else if (type === "quotation" || type === "bill") {
            api.getOdooQuotationPdf(type, name).then(r =>{
                setDocument("data:application/pdf;base64," + r)
            })
        }
        else {
            setDocument("data:application/pdf;base64,"+name.data)
        }
    }

    return(
      <div className={"docLoaderContainer"}>
        <div className={"documentLoader"}>

            {
                (typeof(name) === "number" || name.split(".").pop().toLowerCase() === "pdf") ?
                    <img alt={""} src={PDFIcon} onClick={openModal}/>
                    :
                    <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>
            }

          <Modal open={doc} onClose={() => setDocument(null)}>
              {
                  (typeof(name) === "number" || name.split(".").pop().toLowerCase() === "pdf") ?
                      <embed className={"customModalPDF"} src={doc}/>
                      :
                      <CustomImage className={"caroussel full-page flex-column"} name={name} downloadable={true}/>
              }
          </Modal>
        </div>
      </div>

    )
}

export function DocumentLoaderList({name} ){
    return(
        <div className={"documentLoaderList"}>
            { name?.map(n=><DocumentLoader name={n}/>)}
        </div>
    )
}


/*
import IbatApi from "../../utils/IbatApi";
import Modal from "react-responsive-modal";
import React, {Fragment, useEffect, useState} from "react";
import CustomImage from "./CustomImage";
import PDFIcon from "../../images/pdf-icon.png"
import NewDoc from "../../images/newdoc.png"
import FileUploader from "./FileUploader";
import {text} from "./constants";
import {useSnackbar} from "notistack";
import {IconButton} from "@material-ui/core";

export default function DocumentLoader({name}) {
    const api = new IbatApi()
    const [doc, setDocument] = useState()
    const [b64, setB64] = useState()

    useEffect(()=>{
        api.getFile(name).then(r =>{
            setB64(r.b64)
        })

    })

    function openModal() {
        if (name.split(".").pop() === "pdf"){
            setDocument(b64.replace("octet-stream", "pdf"))
        }
        else{
            api.getFile(name).then(resp => {
                setDocument(resp.url)
            })
        }
    }

    return (
        <div className={"docLoaderContainer"}>
            {
                typeof(name) === "string" ?
                    <div className={"documentLoader flex-row"}>
                        {
                            name.split(".").pop().toLowerCase() === "pdf" ?
                                <img alt={""} src={PDFIcon} onClick={openModal}/>
                                :
                                <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>
                        }

                        <Modal classNames={{modal: "scrollable"}} open={doc} onClose={() => setDocument(null)}>
                            {
                                name.split(".").pop().toLowerCase() === "pdf" ?
                                    <embed className={"customModalPDF"} src={doc}/>
                                    :
                                    <div>
                                        <CustomImage className={"customImageModal"} name={name}/>
                                        <IconButton className={"action-btn validation"} >
                                            <a download href={b64}>Télécharger</a>
                                        </IconButton>
                                    </div>
                            }
                        </Modal>
                    </div>
                    :
                    <div className={"documentLoader flex-row"}>
                        {
                            name.type.toLowerCase() === "pdf" ?
                                <img alt={""} src={PDFIcon} onClick={openModal}/>
                                :
                                <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>
                        }
                        <Modal classNames={{modal: "scrollable"}} open={doc} onClose={() => setDocument(null)}>
                            {
                                name.type.toLowerCase() === "pdf" ?
                                    <embed className={"customModalPDF"} src={doc}/>
                                    :
                                    <div>
                                        <CustomImage className={"customImageModal"} name={name}/>
                                        <IconButton className={"action-btn validation"} >
                                            <a download href={b64}>Télécharger</a>
                                        </IconButton>
                                    </div>
                            }
                        </Modal>
                    </div>
            }
        </div>
    )
}

export function DocumentLoaderList({name, addDoc, type, noHeader}) {
    const api = new IbatApi()
    const [state, setState] = useState(name ?? [])
    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {setState(name)}, [name])


    function handleFiles(files) {

        switch (addDoc.type) {
            case "ConstructionMaterial":
                api.sendMaterialDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionMachinery":
                api.sendMachineryDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "ConstructionDocument":
                api.sendDocumentDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            case "AdminDocument":
                api.sendAdminDocumentDocs(addDoc.id, files).then(resp => {
                    setState(resp["documents"])
                }).catch(e => {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
                })
                break
            default:
                break
        }

    }

    return (
        <Fragment>
            {
                !noHeader &&
                <div className={"header flex-row"}>
                    <img alt={""} src={NewDoc}/>
                    <span>
            Documents liés à la fourniture
          </span>
                </div>
            }
            <div className={"documentLoaderList primary-section flex-row"}>
                {state?.map(n => <DocumentLoader name={n}/>)}
            </div>
            {addDoc && <FileUploader files={[]} onUpload={handleFiles} id={addDoc.id}/>}
        </Fragment>
    )
}
*/
