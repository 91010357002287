import React, {Fragment, useEffect, useRef, useState} from "react";
import "./StepContainerStyle.css"
import { useDrop} from "react-dnd";
import StepFlat from "./StepFlat";
import constants from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";
import {isModifiable} from "../helpers/interventionManager";
import StepFlatPartition from "./StepFlatPartition";



function Container({date,containerSize,onRemove,time,moveStep,onHover,onTimeChange,isScrollActive,eventList}){

    function isMoment(event) {
        return event.start>=date
            && event.start<(date+15*constants.M1);
    }

    function handleDrop(data, date) {
            moveStep(data.id, date)
    }

    function dragElement(id){
        console.log("Dragging "+id)
        return eventList
    }

    const myref=useRef(null)
    const [{isOver}, drop] = useDrop(() => (
        {
            accept:["event"],
            drop: (d,monitor) => (isModifiable(d.intervention)?handleDrop(d,date):undefined),
            collect: monitor => ({isOver:monitor.isOver()})
        }),[eventList])

    useEffect(()=>{
        if(time===6*constants.H1 && isScrollActive) {
            myref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    },[])
    return (
        <div name={timeToDate(time)} ref={myref} className={"agenda-container"}>
            <div
                 ref={drop}
                 style={{height:containerSize,backgroundColor:isOver?'red':'transparent'}}>
                {eventList
                    ?.filter(intv=>isMoment(intv))
                    .map(sp=><StepFlat key={sp.id}
                                       onTimeChange={onTimeChange}
                                       containerSize={containerSize}
                                       event={sp}
                                       pin={sp.intervention}
                                       onRemove={onRemove}
                                       onHover={onHover}
                                       onDrag={e=>isModifiable(sp)&&dragElement(e)}/>)}
            </div>
        </div>
)

}





export default function StepContainer({eventList,onTimeChange,isScrollActive,active,onRemove,beginDate,onClick,onHover,moveStep}) {
    return (

        <Fragment>
                <td className={active?"step-container selected":"step-container"}  onClick={onClick}>

                    {Array.from({length:96},(v,k)=>k*15*constants.M1)
                        .map((k)=><Container key={k}
                                           isScrollActive={isScrollActive}
                                           onRemove={onRemove}
                                           containerSize={10}
                                           onTimeChange={onTimeChange}
                                           eventList={eventList}
                                           onHover={onHover}
                                           date={parseInt(beginDate)+k}
                                           time={k} moveStep={moveStep}/>
                            )}

                </td>
        </Fragment>
    )

}