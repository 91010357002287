import React, {Fragment, useCallback, useEffect, useState} from "react";
import "./operationStyle.css"
import RepairIcon from "../images/repair-icon.png"
import ExportIcon from "../images/export.png"
import NullIcon from "../images/null-statelevel-icon.png"
import IconSee from "../images/eye-see.png"
import IconNotSee from "../images/eye-no-see.png"
import IbatApi from "../utils/IbatApi";
import Modal from "react-responsive-modal";
import {IconButton} from "@material-ui/core";
import CustomImage from "../components/util/CustomImage";
import Layer from "../layer/Layer";
import ImportAnswer from "../images/import-answer.png"
import ImportData from "../images/import-data.png"
import AnswerValidate from "../images/answer.png"
import QuestionIcon from "../images/ask-question.png"
import constants from "../components/util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import Caroussel from "../components/util/Caroussel";
import {useSnackbar} from "notistack";
import StateManager from "../state-manager/StateManager.js";
import {text} from "../components/util/constants";
import AudioComponents from "./AudioComponents";
import FamilyPicture from "./FamilyPicture";
import WorkForceDisplayer from "../work-force-displayer/WorkForceDisplayer";


let api = new IbatApi()

export default function Operation({
                                    isReported,
                                    operation,
                                    pictures,
                                    onExport,
                                    image,
                                    visible,
                                    otherOperations,
                                    depot
                                  }) {

  let reducer = (acc, object) => {
    let findIndex = acc.findIndex(obj => obj.dataId === object.dataId);
    if (findIndex == -1) {
      acc.push(object)
    }
    return acc
  }
  const [state, setState] = useState(operation)
  const repair = operation.metadata?.points.filter(poi => poi.name === constants.REPAIR).reduce(reducer, [])
  const works = operation.metadata?.points.filter(poi => poi.name === constants.WORK).reduce(reducer, [])
  const [layer, setLayer] = useState(false)
  const [pictureList, setPictureList] = useState(pictures)
  const [caroussel, setCaroussel] = useState(false)


  useEffect(() => {
    // saveChanges()
  }, [state.commentaireGestionnaire, state.commentaireRT])
  useEffect(() => setPictureList(pictures), [pictures.length])


  function handlePicture(listPictures) {
    let mothers = listPictures.filter(pic => !pic.motherId)
    let families = []
    for (let mother of mothers) {
      let fam = [...listPictures.filter(childPic => childPic.motherId === mother.id)]
      fam.push(mother)
      families.push(fam)
    }
    return families.filter(fam => fam.length > 0)
  }
  function refreshOperation(id) {
    console.log(id)
    onExport(id)
  }
  function handleLayer() {
    setLayer(!layer)
  }


  return (
    <tr key={state.id} className={"operation-row"} style={state.question ? {backgroundColor: "#500"} : {}}>
      <td>
        <div className={"operationName"}>
          <div>{state && <a name={state.typeName}>{state.typeName}</a>}</div>
          <WorkForceDisplayer operations={[operation]} trigger={text.WORK_FORCE}/>
        </div>
      </td>
      <td id={"state"}>
        <div className={"intervenantCell"}>

          {state.metadata && ( state.metadata.points.length>0)  &&
          <div>
            <div className={"section"}>
              {state.metadata && (state.metadata.suggestion || works) &&<div>
                <span className={"titleSection"}>{text.SUGGESTIONS_AND_ADVICES}</span>
                <div className={"section"}>

                  <ul className={"preconisation"}>
                    {works.map((s,i)=>s?<li key={i}>{s.dataName}</li>:s)}
                  </ul>
                </div>
              </div>}

              {state.metadata?.points.length>0&&
              <Tooltip title={text.DISPLAY_REPAIR_MAP + state.typeName}>
                <IconButton onClick={handleLayer}><img src={RepairIcon}/></IconButton>
              </Tooltip>}
              <ul>
                {repair.map((r,i)=><li key={i}>{text.REPAIRS} {r.temporary? text.TEMP:text.PERM} {text.DONE_WITH}{r.dataName} </li> )}
              </ul>
            </div>
          </div>}
        </div>
      </td>
      {state.metadata&&
      <Modal open={layer} onClose={handleLayer}>
        <Layer points={state.metadata?.points} background={image}/>
      </Modal>
      }
      { isReported &&
      <td>
        <div id={"comments"}>
          <textarea name={"commentaireGestionnaire"} disabled={true}
                    value={state.commentaireGestionnaire ? state.commentaireGestionnaire : ""}/>
        </div>
      </td>}
      <td className={"operation-picture"}>
        <div>
          {pictureList &&
          <div className={"pictures-list"}>
            {
              handlePicture(pictureList).sort((pF1, pF2) => pF1[0].date - pF2[0].date).map((pF, idx) =>
                <div key={idx}>
                  <FamilyPicture index={idx + 1} pictures={pF} onCaroussel={e => setCaroussel(e)} depot={depot}/>
                </div>
              )
            }
            <Modal open={caroussel} onClose={() => setCaroussel(false)}>
              <Caroussel listOperation={otherOperations}
                         list={handlePicture(pictureList).sort((pF1, pF2) => pF1[0].date - pF2[0].date).map((pF, idx) => ({
                           index: idx,
                           family: pF
                         }))}
                         firstFile={caroussel}
                         onExport={refreshOperation}/>
            </Modal>
          </div>
          }
        </div>
      </td>

    </tr>
  )
}

