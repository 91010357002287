import React, {useState, useLayoutEffect, useEffect} from 'react';
import IbatApi from "../../utils/IbatApi";
import InterventionForm from "../forms/InterventionForm";
import InteractionClient from "../bean/InteractionClient";
import EditInterventionDate from "../forms/EditInterventionDate";
import IconEye from "../../images/contact-interaction.png";
import Table from "../../table/Table";
import EditBillForm from "../forms/EditBillForm";
import {IconButton} from "@material-ui/core";
import Modal from "react-responsive-modal";
import Tooltip from "@material-ui/core/Tooltip";
import IconMap from "../../images/button-map.png";
import IconFavoriteSite from "../../images/favorite-site.png";
import CSVDl from "../../images/csv-dl.png";
import STATS from "../../images/statistiques.png";
import MapBean from "./MapBean";
import {useSnackbar} from "notistack";
import {constants, text} from "../util/constants";
import EditEstimate from "../../estimate/EditEstimate";
import {Statistiques} from "../../statistiques/Statistiques";
import SearchSiteForClient from "../forms/SearchSiteForClient";
import SelectClientPrefSite from "../forms/SelectClientPrefSite";




export default function Interventions({defaultFilter}) {
    const {enqueueSnackbar,closeSnackbar}=useSnackbar()

    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "type", label: text.FILE_TYPE, isChecked: true, isFiltrable: true, type: "object"},
        // {id: "insurance", label: text.INSURANCE, isChecked: true, isFiltrable: false, type: "insurance"},
        {id: "devis", label: text.COMMAND_NUM, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "jobType", label: text.JOBTYPE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "reception", label: text.RECEPTION_DATE, isChecked: true, isFiltrable: true, type: "text"},
        // {id: "availabilityDate", label: text.RECEPTION_DATE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "company", label: text.COMPANY_NAME, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "site", label: text.SITE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "address", label: text.ADDRESS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "cp", label: text.ZIPCODE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "ville", label: text.TOWN, isChecked: true, isFiltrable: true, type: "text"},
        {id: "interactions", label: text.INTERACTIONS, isChecked: true, isFiltrable: true, icon: IconEye, type: "popup", popup: InteractionClient},
        {id: "intervention", label: text.INTERVENTION, isChecked: true, isFiltrable: true, type: "dateText"},
        // {id: "estimateDuration", label: text.DURATION_ESTIMATION, isChecked: true, isFiltrable: false, type: "popup", popup: EditEstimateDuration},
        {id: "estimate", label: text.ESTIMATE, isChecked: true, isFiltrable: true, type: "popup", popup: EditEstimate},
        {id: "bill", label: text.BILL, isChecked: true, isFiltrable: true, type: "popup", popup: EditBillForm},

    ]


    const [state, setState] = useState(
        {
            columns: columns,
            interventionList: [],
            pageSize: 20,
            pageNumber: 0,
            count: 0,
            filters: {
                id: "",
                status: defaultFilter?defaultFilter:"",
                type: "",
                site: "",
                siteList: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort:"",
            }
        }
    )
    const [typing,setTyping]=useState(false)
    const [jobType,setJobType]=useState(false)
    const [map,setMap]=useState(false)
    const [loading,setLoading]=useState(false)
    const [selectPrefSite,setSelectPrefSite]=useState(false)
    const [editPrefSite,setEditPrefSite]=useState(false)
    const api = new IbatApi()


    function getJobTypeById(id){
        let name = jobType?.filter(jt => jt.id === id)[0]?.name
        return constants[name] ?? "Couverture"
    }
    function getInterventionsOnly(filter) {
        if (!isFilterEmpty()){
            setLoading(true)
            let f = filter ?? state.filters
            api.getFilterIntervention({
               ...f,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize
            })
                .then(res => {
                    setLoading(false)
                    let interventionList
                    if (jobType){
                        interventionList = res.interventions.map(i => i.jobType = getJobTypeById(i.jobType))
                    } else {
                        interventionList = res.interventions
                    }
                    setState(({...state, interventionList: res.interventions, count: res.count}))
            })
                .catch(e=> {
                    if (e.response?.status === 401) {
                            enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                            // window.open("/login")
                            return
                        }
                    enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
            })
        }
    }

    function getInterventions(){
        if (!jobType){
            api.getJobTypes()
                .then(r=>{
                    setJobType(r)
                })
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
        } else{
            getInterventionsOnly()
        }
    }


    useEffect(() => {
        if (!jobType){
            getInterventions()
        }
        else {
            getInterventionsOnly()
        }

        getPrefSite()
    }, [jobType, typing,state.pageNumber,state.pageSize])


    function isFilterEmpty() {
        // let reduce = Object.keys(state.filters).map((k)=> state.filters[k]==="").reduce((b1, b2) => b1 && b2);
        return false
    }


    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState( ({...state, columns: columns, filters: filter, mapping: false}))
    }

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}}))
    }

    const handleChangePage = (newPage) => {
         setState( ({...state, pageNumber: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        setState( ({...state, pageSize: event.target.value, pageNumber: 0}))
    };

    function clearFilter() {
        setState({
            ...state, filters: {
                id: "",
                status: "",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort: "",
                maps: false
            }
        })
        setTyping(!typing)
    }

    function generatecsv() {
        api.generateCsv(state.filters,state.columns.filter(c=>c.isChecked))
    }

    function getPrefSite() {
        let prefSite = api.getPrefSite()
        if (!prefSite && !selectPrefSite){
            setSelectPrefSite(true)
            return
        }
        if (prefSite){
            setState({...state, filters: {...state.filters, siteList: prefSite}})
        }
    }
    const setPrefSiteEnded = () => {
        let prefSite = api.getPrefSite()
        if (!prefSite) {
            api.setPrefSite("noOne")
        }
        setSelectPrefSite(false)
        if (prefSite){
            let f = {...state.filters, siteList: prefSite}
            setState({...state, filters: f})
            getInterventionsOnly(f)
        }
        if (editPrefSite) {
            api.getSiteByIds(api.getPrefSite().split(",").join(","))
                .then(r => {
                    setEditPrefSite(r.content)
                })
        }

    }


    return (
        <div>
            <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   loading={loading}
                   onHandleChangePage={handleChangePage}
                   onGetData={getInterventions}
                   onHandleColumns={handleColumns}
                   onHandleFilter={handleFilter}
                   rowCount={state.count}
                   onEndTyping={setTyping}
                   filter={state.filters}
                   pageNumber={state.pageNumber}
                   pageSize={state.pageSize}
                   columns={state.columns}
                   list={state.interventionList}
                   onClearFilters={clearFilter}
                   mapdisplay={true}
                   tabName={text.INTERVENTIONS}
                   setPageSize={true}
            >
                <Tooltip title={text.TOOLTIP_DISPLAY_INTERVENTIONS_MAP}>
                    <IconButton disabled={isFilterEmpty()} onClick={()=>setMap(true)}><img alt={"img"} src={IconMap }/></IconButton>
                </Tooltip>

                <Tooltip title={text.TOOLTIP_EDIT_PREF_SITE}>
                    <IconButton onClick={()=>{
                        setEditPrefSite(true)
                        api.getSiteByIds(api.getPrefSite().split(",").filter(s=>s!=="noOne").join(","))
                            .then(r => {
                                setEditPrefSite(r.content)
                            })
                    }}><img alt={"img"} src={IconFavoriteSite}/></IconButton>
                </Tooltip>

                <InterventionForm onSaveSucceed={getInterventions}/>
                {/*<Tooltip title={text.CSV}>
                    <IconButton onClick={generatecsv}><img alt={"CSV"} src={CSVDl}/></IconButton>
                </Tooltip>*/}
                {/*<Tooltip title={text.GENERAL_STATS}>
                    <IconButton onClick={()=>setStats(true)}><img alt={"Statistiques"} src={STATS}/></IconButton>
                </Tooltip>*/}





            </Table>
            {/*<Modal open={stats} onClose={()=>setStats(false)} closeOnOverlayClick={false} >
              <Statistiques onFinish={()=>setStats(false)}/>
            </Modal>*/}

            <Modal open={map} onClose={()=>{clearFilter();setMap(false)}} >
                <div className={"map"}>
                    <MapBean
                        onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                        loading={loading}
                        onHandleChangePage={handleChangePage}
                        onGetData={getInterventions}
                        onHandleColumns={handleColumns}
                        onHandleFilter={handleFilter}
                        rowCount={state.count}
                        onEndTyping={setTyping}
                        filter={state.filters}
                        pageNumber={state.pageNumber}
                        pageSize={state.pageSize}
                        columns={state.columns}
                        pinList={state.interventionList?state.interventionList:[]} pinClickable={true}/>
                </div>
            </Modal>
            <Modal open={selectPrefSite} onClose={setPrefSiteEnded} closeOnOverlayClick={false} >
                <div>
                    <center><h1>Vous n'avez aucun site préféré ?</h1></center>
                    Vous pouvez sélectionner un ou plusieurs site dans la liste ci-dessous pour ne voir que ce qui vous intéresse.
                    <div>
                        <SelectClientPrefSite closeModal={setPrefSiteEnded}/>
                    </div>
                </div>
            </Modal>
            <Modal open={editPrefSite} onClose={()=>setEditPrefSite(false)}>
                <div>
                    <center><h1>Modifier les sites préférés </h1></center>
                    <ul>
                        {(editPrefSite && editPrefSite !== true) &&
                            editPrefSite.map(site => <li>
                                {site.name}<br />
                                <IconButton className={"cancel-button"} onClick={()=>{
                                    api.removePrefSite(site.id)
                                    setEditPrefSite(editPrefSite.filter(s=>s.id !== site.id))
                                }}>Supprimer</IconButton>
                            </li>)
                        }
                    </ul>

                    <IconButton className={"validation-button"} onClick={()=>setSelectPrefSite(true)}>{"Ajouter d'autres sites"}</IconButton>
                </div>
            </Modal>


        </div>
    )
}


