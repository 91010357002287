import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {IconButton} from "@material-ui/core";
import FileUploader from "../util/FileUploader";
import DeleteIcon from "../../images/delete.png";
import ReadyIcon from "../../images/ready.png";
import NotReadyIcon from "../../images/not-ready.png";
import DocumentLoader from "../util/DocumentLoader";
import { text } from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";

export default function SupplyDocument({id, supplyId, typeName, issueDate, isReady, receptionDate, onChange,documents}) {
    const [state, setState] = useState({
        id: id,
        supplyId: supplyId,
        documents: documents?documents:[],
        type: typeName,
        issueDate: issueDate,
        receptionDate: receptionDate ? new Date(receptionDate).toLocaleDateString().split("/").reverse().reduce((s1, s2) => s1 + "-" + s2) : "",
        isReady: isReady,
    })

    const api = new IbatApi();

    function sendDocument(state) {
        const d = {
            type: state.type.id,
            issueDate: state.issueDate,
            receptionDate: state.receptionDate,
            ready: state.isReady,
        }
        api.sendDocument(state.id, d).then(d => console.log(d));
    }

    useEffect(() => sendDocument(state), [state])

    function handleDocument(e) {
        if (e.target.type === "checkbox") {
            setState({...state, [e.target.name]: e.target.checked})
        }
        else {
            setState({...state, [e.target.name]: e.target.value})
        }
    }

    function deleteDocument() {
        api.deleteDocument(state.supplyId, state.id).then(r => onChange(r))
    }

    function handleFiles(files){
     api.sendDocumentDocs(state.id,files).then(cDoc=>setState({...state,...cDoc}))
    }
    return (
        <div className={state.isReady ? "listItem ready" : "listItem"}>
            <Tooltip title={state.type.name}>
                <p>{state.type.name} {new Date(issueDate).toLocaleDateString()}</p>
            </Tooltip>
            <input type={"date"} id={state.id} name={"receptionDate"} value={state.receptionDate} placeholder={text.RECEPTION_DATE} onChange={handleDocument}/>
            <FileUploader files={[]} onUpload={handleFiles} id={state.id}/>
            {state.documents.length >0 && state.documents.map(file=><DocumentLoader name={file}/>)}
            <IconButton onClick={deleteDocument}><img src={DeleteIcon}/></IconButton>
            <label><input type={"checkbox"} id={state.id} name={"isReady"} checked={state.isReady} onChange={handleDocument}/><img src={state.isReady?ReadyIcon:NotReadyIcon}/></label>
        </div>
    )
}