import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {text} from "../components/util/constants";

class ErrorManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

    }

    componentDidCatch(error, info) {
        // Affiche une UI de repli
        this.setState( { hasError: true })
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {

            return<Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"center" }}
                open={this.state.hasError}
                message={text.ERROR_MESSAGE}
            />
        }
        return this.props.children;
    }
}
export default ErrorManager;