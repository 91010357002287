import React, {useState, useEffect} from "react";
import IbatApi from "../../utils/IbatApi";
import {useParams} from "react-router-dom";
import {Statistiques} from "../../statistiques/Statistiques";
import "../style/ClientStyle.css"


export default function Company() {

  const {id} = useParams()
  const api = new IbatApi();


  useEffect(() => {
  }, [id])

  return (
    <Statistiques id={id}/>
  )
}
