import IbatApi from "../../utils/IbatApi";
import {useEffect, useState} from "react";
import '../style/NewServiceProviderSelectStyle.css'
import {IconButton} from "@material-ui/core";
import JobTypeSelector from "./JobTypeSelector";


export default function NewServiceProviderSelect({onSave, jobType, creation, getAll, validationSentence}){

    const [selected, setSelected] = useState()
    const [companies, setCompanies] = useState()
    const [filter, setFilter] = useState({name:"", siren:"", ville: "", jobTypeId: jobType, creation: creation ?? false, getAll: getAll ?? false})
    let api = new IbatApi();


    useEffect(()=>{
        loadOptions()
    }, [filter])

    let loadOptions=()=>{
        return api.getAllCompaniesByFilter(filter).then(resp => {
            setCompanies(resp.filter(c => c.id > -1))
        })
    }
    function handleInputChange(d){
        // setName(d)
        setFilter( {...filter, [d.target.id]: d.target.value})
        // loadOptions()
    }

    function getCompanyRow(c){
        return (
            <tr className={selected?.id === c.id ? "selected": "result-row"} onClick={()=>setSelected(c)}>
                <td>{c.id}</td>
                <td>{c.name}</td>
                <td>{c.address}</td>
                <td>{c.cp}</td>
                <td>{c.ville}</td>
                <td>{c.phone}</td>
                <td>{c.siren}</td>
            </tr>
        )
    }

    useEffect(()=>{
        if (!companies){
            loadOptions()
        }
    })
    return (
        <div className={"new-service-provider-select"}>
            <h1>Rechercher un prestataire</h1>
            <div className={"filters"}>
                <input type={"text"} onChange={handleInputChange} placeholder={"Nom du prestataire"} id={"name"}/>
                <input type={"text"} onChange={handleInputChange} placeholder={"Siren"} id={"siren"}/>
                <input type={"text"} onChange={handleInputChange} placeholder={"Ville"} id={"ville"}/>
                <JobTypeSelector jobType={jobType} onChange={handleInputChange}/>
            </div>

            {companies && <table className={"result"}>
                <tr>
                    <td>id</td>
                    <td>Nom</td>
                    <td>Adresse</td>
                    <td>Code Postal</td>
                    <td>Ville</td>
                    <td>Téléphone</td>
                    <td>Siren</td>
                </tr>
                {companies?.map(c=>getCompanyRow(c))}
            </table>}

            {selected &&
                <IconButton className={"validation-button"} onClick={()=>onSave(selected)}>{validationSentence ?? "Ajouter ce prestataire à ma liste."}</IconButton>
            }
        </div>
    )
}