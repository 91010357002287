import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import constants, {text} from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {EditText, EditTextarea} from "react-edit-text";
import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import CommentIcon from "../../images/comment.png";
import {IconButton} from "@material-ui/core";
import BonCommande from "../../images/bc-icon.png";
import OpenFormIcon from "../../images/new-interv.png";
import ContactIllustIcon from "../../images/icon-contact.png";
import ReportIcon from "../../images/illust-button-pdf.png";
import Modal from "react-responsive-modal";
import {DocumentLoaderList} from "../util/DocumentLoader";
import CancelInterventionIcon from "../../images/cancel-interv-icon.png";
import CreateIntervention from "../forms/CreateIntervention";
import InterventionForm from "../forms/InterventionForm";
import ContactIcon from "../../images/contact-interaction.png";
import EditInterventionTeam from "../forms/EditInterventionTeam";
import EditInterventionDate from "../forms/EditInterventionDate";
import InteractionClient from "./InteractionClient";

export default function Request(){
    const api = new IbatApi()
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {id} = useParams()
    const [show, setShow] = useState(false)
    const [state, setState] = useState({})
    const [title, setTitle] = useState("")
    const [jobType,setJobType]=useState(false)
    const [intervention, setIntervention] = useState([])
    const [interventionForm, setInterventionForm] = useState({})
    const [isSavable, setIsSavable] = useState(false)
    const [openDate, setOpenDate] = useState(false)
    const [contacts, setContacts] = useState(false)
    const [interventionMapped, setInterventionMapped] = useState(false)
    const mySiret = api.getTokenData()?.siret

    useEffect(() => {
        if(!state.id && !jobType){
            getRequest()

        }
        if (!jobType){
            api.getJobTypes()
                .then(r=>{
                    setJobType(r)
                })
                .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
        }
        if (!interventionMapped && intervention.length>0 && jobType){
            mapJobType()
        }
    }, [intervention, jobType, id, state])


    function mapJobType(){
        setInterventionMapped(true)
        intervention.map(i=> i.jobType = constants[jobType?.filter(jt => jt.id === i.jobType)[0]?.name]??"Couverture")
    }

    function getRequest(){
        api.getRequest(id)
            .then(r=> {
                setState(r)
                getRequestIntervention(r.devis.map(d => d.id))
            })
            .catch(e=> enqueueSnackbar(text.SNACKBAR_ERROR, {variant:"error"}))
    }
    function getRequestIntervention(devisIdList) {
        setInterventionMapped(false)
        api.getRequestIntervention(devisIdList).then(i => {
            setIntervention([...i])
        })
    }

    function handleRequestTitle(e) {
        setTitle(e)
    }
    function saveRequestTitle() {
        enqueueSnackbar('Permissions invalides', {variant: "error"})
        /*api.setDevisClientIdBoncommande(id,clientIdBCommande).then(resp=>getDevis())*/
    }

    function handleCommentaire(e) {
        api.updateRequestComment(id, state.comment)
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }
    function handleCommentaireChange(e) {
        if(e.length>constants.MAX_LENGTH){
            return
        }
        setState({...state,comment:e})
    }


    function handleContacts(id) {
        setContacts(id)
    }


    function handleData(data) {
        setInterventionForm(data)
        handleSavable(data)
    }
    function handleSavable(data) {
        setIsSavable(data.type)
    }

    function handleSave() {
        if (!isSavable) {
            setShow(false)
            return
        }
        let intervention = {
            etat: interventionForm.team?.name && interventionForm.intervention ? constants.SCHEDULED : constants.TOSCHEDULED,
            idSecteur: interventionForm.secteur,
            idTypeIntervention: interventionForm.type,
            teamId: interventionForm.team ? interventionForm.team.id : null,
            dateIntervention: interventionForm.intervention ? new Date(interventionForm.intervention).getTime() : 0,
            jobTypeId: interventionForm.jobTypeId,
            estimateDuration: 3600,
        }
    }

    return (
        <div className={"devis-bean"}>

            <h1>{text.REQUEST_NUM} {id}</h1>
            <Tooltip title={text.TOOLTIP_CMD_REF}>
                <h2><EditText className={"CmdRef"} onChange={handleRequestTitle}
                              value={title ? title : constants.NULL} onBlur={saveRequestTitle}/>
                </h2>
            </Tooltip>


            <div className={"devis-container"}>
                <div className={"devis-head"}>
                    <div className={"tabrow"}>
                        <div className={"tabcell"}> {text.REQUEST_BY}</div>
                        <div className={"tabcell"}>JCVD</div>
                        <div className={"tabcell"}>Le </div>
                        <div className={"tabcell"}>{state.requestDate && new Date(state.requestDate).toLocaleDateString()}</div>
                    </div>
                    <div className={"tabrow"}>
                        {/*<div className={"tabcell"}>{text.CLIENT_2}</div>
                        {state.client && <a className={"tabcell"} href={`/client/${state.client.id}`}> {state.client.name}</a>}*/}

                        <div className={"tabcell"}>{text.SITE_2}</div>
                        {state.siteClient && <a className={"tabcell"} href={`/site/${state.siteClient.site.id}`}> {state.siteClient.site.name}</a>}
                        <div className={"tabcell"}>{text.REQUEST_CONTENT}</div>
                        <div className={"tabcell"}>{state.content}</div>
                    </div>
                </div>
            </div>

            <div className={"devis-comments"}>
                <h3><img src={CommentIcon}/>{text.NOTES_AND_COMMENTS}</h3>
                <EditTextarea className={"textedit"} value={state?.comment??""} onChange={handleCommentaireChange} rows={6}  onBlur={handleCommentaire}> </EditTextarea>
            </div>

            <div className={"devis-intervention"}>
                <h2>{text.DEVIS_LINKED}</h2>
                        {state?.devis?.map(d=> {
                            return [
                                <table>
                                    <thead>
                                    <tr>
                                        <th>{text.INTERVENTION_NUMBER}</th>
                                        {/*<th>{text.STATUS}</th>*/}
                                        {/*<th>{text.FILE_TYPE}</th>*/}
                                        <th>{text.COMPANY_NAME}</th>
                                        {/*<th>{text.JOBTYPE}</th>*/}
                                        <th>{text.CONTACTS}</th>
                                        <th>{text.DATE}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><a href={`/devis/${d.id}`} target={"_blank"}>{d.id}</a></td>
                                        <td>{d.company.name}</td>
                                        <td>
                                            <Tooltip title={text.TOOLTIP_LIST}>
                                                <IconButton onClick={() => handleContacts(d.id)}><img src={ContactIcon}/></IconButton>
                                            </Tooltip>
                                        </td>
                                        <td>{new Date(d.dateReception).toLocaleDateString()}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={4}>
                                            <table style={{width: "100%"}}>
                                                <thead>
                                                    <tr>
                                                        <th>{text.INTERVENTION_NUMBER}</th>
                                                        <th>{text.STATUS}</th>
                                                        <th>{text.FILE_TYPE}</th>
                                                        <th>{text.JOBTYPE}</th>
                                                        <th>{text.CONTACTS}</th>
                                                        <th>{text.INTERVENTION_START}</th>
                                                        <th>{text.INTERVENTION_END}</th>
                                                        {/*<th>{text.OBJECT}</th>*/}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {intervention.length > 0 && intervention.filter(i => i.devis.id === d.id).map(i => <tr>
                                                    <td><a href={`/intervention/${i.id}`}> {i.id}</a></td>
                                                    <td>{text.STATUS_TRADUCTION[i.status]}</td>
                                                    <td>{i.type.name}</td>
                                                    <td>{i.jobType}</td>
                                                    <td>
                                                        <Tooltip title={text.TOOLTIP_LIST}>
                                                            <IconButton onClick={() => handleContacts(i.id)}><img src={ContactIcon}/></IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td
                                                        onClick={() => setOpenDate(i.id)}>{i.dateIntervention > 0 ? new Date(i.dateIntervention).toLocaleDateString() : "-"}</td>
                                                    <td>{i.endIntervention > 0 ? new Date(i.endIntervention).toLocaleDateString() : "-"}</td>
                                                    {/*<td>{i.object ? i.object : text.TO_DEFINE}</td>*/}
                                                </tr>)}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>, <br />, <br />, <br />
                        ]
                        })}
            </div>

            <div className={"devis-sideBar"}>
                {!mySiret && <InterventionForm site={state?.siteClient?.site} forRequest={false} requestId={id} onSaveSucceed={(r)=>setState(r)}/>}
                {/*<Tooltip title={text.TOOLTIP_CLIENT_CHAT}>
                    <IconButton onClick={() => handleContacts(id)}><img src={ContactIllustIcon}/></IconButton>
                </Tooltip>
                <Tooltip title={text.TOOLTIP_SUPPLY}>
                    <IconButton onClick={()=>setApproModal(!approModal)}><img src={ApproIcon}/></IconButton>
                </Tooltip>
                <Tooltip title={text.TOOLTIP_SAVED_REPORT_LIST}>
                    <IconButton onClick={() => handleReport(id)}><img src={ReportIcon}/></IconButton>
                </Tooltip>
                <Modal open={report} onClose={() => setReport(false)}>
                    {intervention.flatMap(i => i.generatedReport).length > 0 ?
                        <DocumentLoaderList name={intervention.flatMap(i => i.generatedReport)}/> : text.NO_REPORT_SAVED}
                </Modal>
                <IconButton disabled>
                    <img src={CancelInterventionIcon}/>
                </IconButton>*/}

                {/*<Modal open={show} onClose={() => setShow(false)}>
                    <div className={"commande-form"}>
                        <div className={"commande-head"}>
                            <h3>{text.CREATE_INTERV_IN_REQUEST}</h3>
                        </div>

                        <div className={"commande-data"}>
                            <CreateIntervention onValid={handleData} classic mySiret={mySiret} requestMode={false} site={state.siteClient.site} />
                        </div>
                        <IconButton className={`${isSavable ? "validation" : "cancel"}-button`} onClick={() => {
                            handleSave();
                            setShow(false)
                        }}>
                            {isSavable ? text.CREATE : text.CANCEL}
                            <img src={isSavable?ValidateFormIcon:OpenFormIcon}/>
                        </IconButton>
                    </div>
                </Modal>*/}


            </div>

            <Modal open={contacts} onClose={() => setContacts(false)}>
                <InteractionClient id={contacts} devis={contacts === id}/>
            </Modal>
        </div>
    )
}